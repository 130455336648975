import { Line, mixins } from 'vue-chartjs';
import globalOptionsMixin from '@/components/Charts/globalOptionsMixin';

const { reactiveProp } = mixins;
export default {
  name: 'line-chart',
  extends: Line,
  mixins: [reactiveProp, globalOptionsMixin],
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    extraOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      ctx: null,
    };
  },
  mounted() {
    this.$watch(
      'chartData',
      (newVal) => {
        this.renderChart(newVal, this.extraOptions);
      },
      { immediate: true },
    );
  },
};
