<template>
  <div>
    <md-dialog :md-active.sync="open" class="modalEdit modal-img-archive">
      <div class="w-100 position-fixed mobile-cancel">
        <span class="material-icons close text-right" @click="closeModal"
          >close</span
        >
      </div>
      <div class="body-modal-img-archive py-5 modal-body">
        <md-dialog-title class="md-title pb-3 add-category text-left">
          {{ isEmpty(item) ? 'EDITAR' : 'AGREGAR' }} ENLACE
        </md-dialog-title>

        <div class="dialog-body">
          <div class="row">
            <div class="form-group col-md-12 mb-2 pt-0">
              <h4 class="text-bold mb-3 float-left">NOMBRE</h4>
              <base-input
                v-model="val.title"
                name="name"
                placeholder="Titulo del archivo."
              ></base-input>
            </div>
            <div class="form-group col-md-12 mb-2">
              <h4 class="text-bold mb-3 float-left">DESCRIPCIÓN</h4>
              <textarea
                v-model="val.description"
                name="description"
                class="form-control"
                placeholder="Coloca la descripción."
                rows="3"
              ></textarea>
            </div>
            <div class="form-group col-md-12 mb-2 text-left">
              <h4 class="text-bold mb-3 mt-4 float-left">ENLACE</h4>
              <base-input
                v-model="val.link"
                name="link"
                placeholder="Insertar link"
              ></base-input>
            </div>
          </div>
        </div>

        <md-dialog-actions class="modal-footer">
          <md-button class="btn-primary w-auto" @click="closeModal">
            CANCELAR
          </md-button>
          <md-button
            class="btn-primary ml-0 w-auto"
            @click="isEmpty(item) ? edit() : send()"
          >
            {{ isEmpty(item) ? 'EDITAR' : 'AGREGAR' }}
          </md-button>
        </md-dialog-actions>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import * as _ from 'lodash';
import {
  MEDIA_CREATE_FILE,
  MEDIA_GET_PARTNERS_BY_CATEGORIE,
  MEDIA_UPDATE_FILE,
  MEDIA_UPLOAD_FILE,
} from '@/store/actions/media';

export default {
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
      default: null,
    },
    categorySelected: {
      type: Number || String,
      default: null,
    },
  },
  data() {
    return {
      val: {
        title: null,
        description: null,
        link: null,
      },
    };
  },
  methods: {
    isEmpty(e) {
      return !_.isEmpty(e);
    },
    notifyAlert(message = '', type = 'success') {
      this.$notify({
        type,
        verticalAlign: 'bottom',
        horizontalAlign: 'left',
        message,
      });
    },
    getPartnersByCategory() {
      this.$store.dispatch(
        MEDIA_GET_PARTNERS_BY_CATEGORIE,
        this.categorySelected,
      );
    },
    isValidLink() {
      if (!this.val.link) return false;
      const expression =
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
      const regex = new RegExp(expression);
      return this.val.link.match(regex);
    },
    send() {
      if (this.isValidLink()) {
        const formData = this.parseDataIsLink();
        this.$store.dispatch(MEDIA_UPLOAD_FILE, formData).then((resp) => {
          this.$store
            .dispatch(MEDIA_CREATE_FILE, {
              ...resp,
              filename: this.val.title,
              description: this.val.description,
            })
            .then(() => {
              this.notifyAlert('Enlace subido');
              if (this.categorySelected) this.getPartnersByCategory();
            })
            .catch(() =>
              this.notifyAlert(
                'Error al subir el enlace. Por favor, vuelve a intentar',
                'danger',
              ),
            )
            .finally(() => this.closeModal());
        });
      } else {
        this.val.link = null;
        this.notifyAlert(
          'El enlace tiene que seguir el ejemplo indicado',
          'danger',
        );
      }
    },
    edit() {
      if (!this.val.link || !this.isValidLink()) {
        this.notifyAlert(
          'El enlace tiene que seguir el ejemplo indicado',
          'danger',
        );
        return;
      }
      const formData = this.parseDataIsLink();
      this.$store.dispatch(MEDIA_UPLOAD_FILE, formData).then((res) => {
        res.id = this.item.id;
        this.$store
          .dispatch(MEDIA_UPDATE_FILE, res)
          .then(() => {
            this.notifyAlert('Se ha actualizado el archivo');
          })
          .catch(() =>
            this.notifyAlert(
              'No se pudo actualizar el archivo. Vuelve a intentar',
              'danger',
            ),
          )
          .finally(() => this.closeModal());
      });
    },
    parseDataIsLink() {
      const formData = new FormData();
      formData.append('url', this.val.link);
      formData.append('title', this.val.title);
      formData.append('description', this.val.description);
      formData.append('type', 'url');
      formData.append('category', this.categorySelected);
      return formData;
    },
    closeModal() {
      this.$emit('closeModal', 'mediaUrl');
      this.val = {
        title: null,
        description: null,
        link: null,
      };
    },
  },
  watch: {
    item: function (newVal) {
      console.log('watch', newVal);
      if (this.isEmpty) {
        this.val = {
          title: newVal.filename,
          description: newVal.description,
          link: newVal.url,
        };
      }
    },
  },
  computed: {
    open: {
      // getter
      get: function () {
        return this.active;
      },
      // setter
      set: function () {
        this.closeModal();
      },
    },
  },
};
</script>
