<template>
  <div class="container-fluid text-default">
    <div class="row">
      <div class="col">
        <div v-if="!loading && currentOrder">
          <card>
            <div class="row no-gutters">
              <div
                class="col-mobile-orderDetails col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4 my-1"
              >
                <p class="font-weight-bold mb-1">Usuario:</p>
                <span>{{ currentOrder.user.name }}</span>
              </div>
              <div
                class="col-mobile-orderDetails col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4 my-1"
              >
                <p class="font-weight-bold mb-1">Dia:</p>
                <span>{{
                  getDateFormat(currentOrder.invoice.created_at)
                }}</span>
              </div>
              <div
                class="col-mobile-orderDetails col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4 my-1"
              >
                <p class="font-weight-bold mb-1">Cursos:</p>
                <span>{{ currentOrder.invoice.cant }}</span>
              </div>
              <div
                class="col-mobile-orderDetails col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4 my-1"
              >
                <p class="font-weight-bold mb-1">Metodo:</p>
                <span v-if="currentOrder.payment_method == 'creditcard'"
                  >Tarjeta de Credito</span
                >
                <span v-else>Paypal</span>
              </div>
              <div
                class="col-mobile-orderDetails col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4 my-1"
              >
                <p class="font-weight-bold mb-1">Estado:</p>
                <!-- <span v-if="currentOrder.statusPartner ==pending">
                            Pendiente
                        </span> -->
                <span> Completado </span>
              </div>
            </div>
            <h3 class="mt-3">
              Codigo de invoice: {{ currentOrder.invoice.NumFacturaMaq }}
            </h3>
            <p><b>Monto</b></p>
            <div class="d-flex justify-content-between">
              <span class="pb-0 pt-1">Total:</span>
              <span class="pb-0 pt-1"
                >${{ currentOrder.invoice.total.toFixed(2) }}</span
              >
            </div>
            <div class="d-flex justify-content-between pb-0 m-0">
              <small class="pb-0 m-0">Comisión del pago</small>
              <small class="pb-0 m-0">-${{ currentOrder.invoice.fee }}</small>
            </div>
            <hr />
            <div class="d-flex justify-content-end">
              <p>${{ currentOrder.invoice.profit }}</p>
            </div>
          </card>
        </div>
        <div v-else>Cargando</div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from 'axios';
import config from '@/config/config';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { INVOICE_GET_DETAIL_BY_INVOICEID } from '@/store/actions/order';
export default {
  data() {
    return {
      currentOrder: null,
      loading: false,
    };
  },
  async mounted() {
    let code = this.$route.params.code;
    this.loading = true;
    this.$store
      .dispatch(INVOICE_GET_DETAIL_BY_INVOICEID, code)
      .then((resp) => {
        this.currentOrder = this.getInvoiceByCodeForAdmin;
        console.log(this.currentOrder);
        this.loading = false;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    getDateFormat(date) {
      return moment(date).format('ll');
    },
  },
  computed: {
    ...mapGetters(['getInvoiceByCodeForAdmin']),
  },
};
</script>

<style></style>
