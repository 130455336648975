<template>
  <div>
    <md-dialog :md-active.sync="open" class="modal-category">
      <span class="material-icons close text-right" @click="closeModal"
        >close</span
      >
      <div class="body-modal-category">
        <md-dialog-title>
          {{ isEmpty(item) ? 'EDITAR' : 'NUEVA' }} CATEGORIA</md-dialog-title
        >

        <md-dialog-content class="mt-2">
          <span> Ingrese el nombre de la categoria.</span></md-dialog-content
        >

        <md-dialog-content>
          <md-field>
            <div class="md-field md-theme-default md-has-placeholder pt-0">
              <md-input placeholder="Nueva categoria" v-model="val"></md-input>
            </div>
          </md-field>
        </md-dialog-content>

        <md-dialog-actions>
          <md-button
            class="md-button md-primary md-theme-default"
            @click="closeModal"
            >CANCELAR</md-button
          >
          <md-button
            class="md-button md-primary md-theme-default"
            @click="isEmpty(item) ? edit() : send()"
            >{{ isEmpty(item) ? 'EDITAR' : 'AGREGAR' }}</md-button
          >
        </md-dialog-actions>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import {
  MEDIA_CATEGORY_UPDATE_NAME,
  MEDIA_CREATE_CATEGORY,
} from '@/store/actions/media';
import * as _ from 'lodash';

export default {
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      val: null,
    };
  },
  methods: {
    isEmpty(e) {
      return !_.isEmpty(e);
    },
    notifyAlert(message = '', type = 'success') {
      this.$notify({
        type,
        verticalAlign: 'bottom',
        horizontalAlign: 'left',
        message,
      });
    },
    send() {
      this.$store
        .dispatch(MEDIA_CREATE_CATEGORY, { title: this.val })
        .then(() => this.notifyAlert('Se ha creado la categoria con éxito'))
        .catch(() =>
          this.notifyAlert('No se ha podido crear la categoria', 'danger'),
        )
        .finally(() => this.closeModal());
    },
    edit() {
      const category = {
        id: this.item.id,
        title: this.val,
      };
      this.$store
        .dispatch(MEDIA_CATEGORY_UPDATE_NAME, category)
        .then(() => this.notifyAlert('Se ha editado el nombre de la categoria'))
        .catch(() =>
          this.notifyAlert(
            'No Se ha podido actualizar el nombre de la categoria',
            'danger',
          ),
        )
        .finally(() => this.closeModal());
    },
    closeModal() {
      this.$emit('closeModal', 'category');
      this.val = null;
    },
  },
  watch: {
    item: function (newVal) {
      this.val = _.isEmpty(newVal) ? null : newVal.title;
    },
  },
  computed: {
    open: {
      // getter
      get: function () {
        return this.active;
      },
      // setter
      set: function () {
        this.closeModal();
      },
    },
  },
};
</script>
