<template>
  <div class="container-fluid">
    <div
      class="col-12 row justify-content-between container-partners__search pr-0 pl-0 ml-0 mb-3"
    >
      <div
        class="col-xs-mobile col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pl-0"
      >
        <form class="d-flex">
          <i class="fas fa-search fa-flip-horizontal"></i>
          <input
            v-model="searchValue"
            aria-label="Search"
            class="form-control filter-input__category inputFilter"
            placeholder="Buscar por nombre de usuario"
            style="height: 45px"
            type="text"
            @keydown="searchUserInvoices"
          />

          <div class="mr-3">
            <div class="md-layout-item" style="padding: 0 !important">
              <md-field class="sort-box m-0" style="">
                <md-select
                  v-model="sortType"
                  class="custom-select filter inputFilter sort-select sort-portrait md-filter-input md-sort-select m-0"
                  style="height: 45px"
                >
                  <md-option hidden selected value="">Filtrar por</md-option>
                  <md-option value="DESC">Nuevo</md-option>
                  <md-option value="ASC">Antiguo</md-option>
                </md-select>
              </md-field>
            </div>
          </div>

          <div class="">
            <DatePicker @change-date="changeDates" />
          </div>
          <div class="ml-auto">
            <base-button
              class="primary btn-maquillate py-2 px-3"
              style="height: 45px"
              type="submit"
            >
              Exportar
            </base-button>
          </div>
        </form>
      </div>
    </div>
    <card
      class="col-12 last-orders px-0 table-container"
      header-classes="bg-transparent"
      type="white"
    >
      <el-table
        :data="getUserInvoices.items"
        class="table-responsive table-flush order-last-list mb-4"
        header-row-class-name="thead-light"
      >
        <el-table-column label="Fecha" min-width="65px" prop="date" sortable>
          <template #default="{ row }">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="font-weight-500 name mb-0 text-sm">{{
                  utcDate(row.created_at)
                }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Código de Factura"
          min-width="120px"
          prop="code"
          sortable
        >
          <template #default="{ row }">
            <div class="media align-items-center">
              <span class="status font-weight-500 name">{{
                row.transaction_invoice_id
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="N° Orden" min-width="120px" prop="id" sortable>
          <template #default="{ row }">
            <div class="media align-items-center">
              <span class="status font-weight-500 name">{{
                row.transaction_id
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          class="text-center"
          label="Descripcion"
          min-width="100px"
          prop="description"
          sortable
        >
          <template #default="{ row }">
            <div v-for="(order, index) in row.orders" :key="index">
              <router-link
                :to="`/cursos/curso/${order.course.id}/${order.course.slug_name}`"
                style="
                  color: #b7227e !important;
                  text-decoration: underline !important;
                  font-weight: bold;
                "
              >
                {{ order.course.name }}
              </router-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Usuario"
          min-width="90px"
          prop="enable"
          sortable
        >
          <template #default="{ row }">
            <div class="media align-items-center">
              <div class="media-body order-date">
                <span class="font-weight-500 name mb-0 text-sm">
                  {{ row.user && row.user.name ? row.user.name : '' }}
                </span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Monto"
          min-width="60px"
          prop="expiration"
          sortable
        >
          <template #default="{ row }">
            <div class="media align-items-center">
              <div class="media-body order-amount">
                <span class="font-weight-500 name mb-0 text-sm">
                  ${{ row.profit }}
                </span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Invoice PDF" min-width="70px" prop="pdf">
          <template #default="{ row }">
            <div class="avatar-group">
              <a
                :href="downloadInvoice(row.transaction_invoice_id)"
                class="style-avatar"
                target="_blank"
              >
                <i class="ni ni-single-copy-04"></i>
              </a>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <div
        v-if="totalPages > 1"
        class="card-footer pt-4 pb-2 d-flex justify-content-end border-top-0"
      >
        <base-pagination
          :value="page"
          class="pagination-style"
          :page-count="totalPages"
          @input="paginationChange"
        ></base-pagination>
      </div>
    </card>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker';
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';
import { FETCH_ADMIN_USER_INVOICES } from '../../../../store/actions/user';
import { Table, TableColumn } from 'element-ui';
import BaseButton from '../../../../components/BaseButton';
import config from '@/config/config';
import BasePagination from '../../../../components/BasePagination';

export default {
  name: 'UserInvoices',
  components: {
    DatePicker,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BaseButton,
    BasePagination,
  },
  props: {
    perPage: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      startDate: moment().startOf('month').format(),
      endDate: moment().endOf('month').format(),
      sortType: 'DESC',
      isLoading: false,
      searchValue: '',
      page: 1,
    };
  },
  computed: {
    ...mapGetters(['getUserInvoices']),
    totalPages() {
      return Math.ceil(this.getUserInvoices.total / this.perPage);
    },
  },
  watch: {
    sortType: function (newValue) {
      this.fetchUserInvoices({
        per_page: 20,
        order_by: newValue,
        search: this.searchValue,
        start_date: this.startDate,
        end_date: this.endDate,
      });
    },
  },

  mounted() {
    this.fetchUserInvoices();
  },

  methods: {
    searchUserInvoices(e) {
      if (e.key === 'Enter') {
        this.fetchUserInvoices();
      }
    },
    fetchUserInvoices(
      filter = {
        per_page: 20,
        order_by: this.sortType,
        search: this.searchValue,
        start_date: this.startDate,
        end_date: this.endDate,
      },
    ) {
      this.$store
        .dispatch(FETCH_ADMIN_USER_INVOICES, filter)
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.notify(
            'danger',
            'Tenemos problemas para cargar la factura de los usuarios',
          );
        });
    },
    utcDate(date) {
      return moment.utc(date).format('l');
    },

    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    downloadInvoice(invoiceId) {
      return `${config.data.apiUrl}/invoice/download/${invoiceId}`;
    },
    changeDates({ startDate, endDate, ranged }) {
      this.startDate = moment(startDate).format();
      this.endDate = moment(endDate).format();
      this.ranged = ranged;
      this.fetchUserInvoices({
        per_page: 12,
        order_by: this.sortType,
        search: this.searchValue,
        start_date: this.startDate,
        end_date: this.endDate,
      });
    },
    async paginationChange(page) {
      this.isLoading = true;
      this.page = page;
      await this.$store.dispatch(FETCH_ADMIN_USER_INVOICES, {
        page: this.page,
        per_page: this.perPage,
      });
      this.isLoading = false;
    },
  },
};
</script>

<style>
.reportrange-text {
  padding: 0.72rem 10px !important;
}
</style>
