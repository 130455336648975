<template>
  <div>
    <md-dialog v-model:md-active="open" class="modalEdit modal-img-archive">
      <div class="w-100 position-fixed mobile-cancel">
        <span
class="material-icons close text-right"
@click="closeModal"
          >close</span
        >
      </div>
      <div class="body-modal-img-archive py-5 modal-body">
        <md-dialog-title class="md-title pb-3 add-category text-left">
          {{ isEmpty(item) ? 'EDITAR' : 'AGREGAR' }}
          <span v-if="typeFile === 'image'" class="text-uppercase">imagen</span>
          <span
v-else-if="typeFile === 'file'"
class="text-uppercase"
            >archivo</span
          >
          <span v-else class="text-uppercase">{{ typeFile }}</span>
        </md-dialog-title>

        <div class="dialog-body">
          <div class="row">
            <div class="form-group col-md-12 mb-2 pt-0">
              <h4 class="text-bold mb-3 float-left">NOMBRE</h4>
              <base-input
                v-model="val.title"
                name="name"
                placeholder="Titulo del archivo."
              ></base-input>
            </div>
            <div class="form-group col-md-12 mb-2">
              <h4 class="text-bold mb-3 float-left">DESCRIPCIÓN</h4>
              <textarea
                v-model="val.description"
                name="description"
                class="form-control"
                placeholder="Coloca la descripción."
                rows="3"
              ></textarea>
            </div>
            <div
              v-if="(item.url || isValidLink()) && typeFile === 'image'"
              class="form-group col-md-12 mb-2"
            >
              <h4 class="text-bold text-left">VISTA PREVIA</h4>
              <img
                :src="val.link ? val.link : item.url"
                alt="preview"
                class="mt-2"
                width="500"
                height="400"
              />
            </div>
            <div class="form-group col-md-12 mb-2 text-left">
              <h4 class="text-bold">
                <span
v-if="typeFile === 'image'"
class="text-uppercase"
                  >imagen</span
                >
                <span
v-else-if="typeFile === 'file'"
class="text-uppercase"
                  >archivo</span
                >
                <span v-else class="text-uppercase">{{ typeFile }}</span>
              </h4>
              <p class="text-muted">
                Los formatos permitidos son:
                <span v-if="typeFile" class="text-uppercase">{{
                  options[typeFile].acceptedFiles
                }}</span>
              </p>
              <div class="progress progress-show-courses">
                <div
                  v-if="this.amount > 0 && this.amount < 100"
                  class="progress-bar"
                  role="progressbar"
                  :style="{ width: this.amount + '%' }"
                  :aria-valuenow="this.amount"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
                <div
                  v-else-if="this.amount == 100 || this.amount == 0"
                  class="progress-bar"
                  role="progressbar"
                  :style="{ width: '0%' }"
                  :aria-valuenow="'0'"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
                <!-- <div v-else-if="lesson.culminated === 0 && lesson.porcentege_video === 'null'"></div> -->
                <!-- <div
            class="progress-bar progress-bar-video"
            role="progressbar"
            v-bind:style="{width:data.porcentege_video}"
            :aria-valuenow="data.porcentege_video"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div> -->
              </div>
              <div v-if="typeFile !== 'video'">
                <vue2Dropzone
                  id="files"
                  ref="files"
                  :options="options[typeFile]"
                  @vdropzone-error="errorFileEvent('files')"
                  @vdropzone-file-added="fileAdded"
                  @vdropzone-removed-file="removeFile('files')"
                  @vdropzone-sending="sendingFileEvent"
                  @vdropzone-total-upload-progress="uploadProgress"
                  @vdropzone-success="successUploadFileLesson"
                />
              </div>
              <div v-if="typeFile === 'video'">
                <vue2Dropzone
                  ref="dropzone"
                  @vdropzone-file-added="fileAddedB"
                  id="videodropzone"
                  @vdropzone-sending="sendingEventB"
                  :options="dropzoneOptions"
                  @vdropzone-success="successUploadB"
                  @vdropzone-complete="completeEventB"
                  @vdropzone-total-upload-progress="uploadProgressB"
                  @vdropzone-canceled="canceledFileB"
                  @vdropzone-removed-file="removedFileB"
                  @vdropzone-processing="processingFileB"
                  @vdropzone-complete-multiple="completeMultipleFileB"
                ></vue2Dropzone>
              </div>
              <h4 class="text-bold mb-3 mt-4 float-left">
                ENLACE
                <span
v-if="typeFile === 'image'"
class="text-uppercase"
                  >la imagen</span
                >
                <span
v-else-if="typeFile === 'file'"
class="text-uppercase"
                  >el archivo</span
                >
                <span v-else class="text-uppercase">el {{ typeFile }}</span>
              </h4>
              <base-input
                v-model="val.link"
                name="link"
                placeholder="Insertar link"
              ></base-input>
            </div>
          </div>
        </div>

        <md-dialog-actions class="modal-footer">
          <md-button class="btn-primary w-auto" @click="closeModal">
            CANCELAR {{ saludo }}
          </md-button>
          <md-button
            class="btn-primary ml-0 w-auto"
            @click="isEmpty(item) ? edit() : send()"
          >
            {{ isEmpty(item) ? 'EDITAR' : 'AGREGAR' }}
          </md-button>
        </md-dialog-actions>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import * as _ from 'lodash';

import {
  MEDIA_CREATE_FILE,
  MEDIA_DELETE_FILE_IN_CDN,
  MEDIA_GET_PARTNERS_BY_CATEGORIE,
  MEDIA_UPDATE_FILE,
  MEDIA_UPLOAD_FILE,
} from '@/store/actions/media';

import {
  VIDEO_LIST_VIDEOS
} from '@/store/actions/video';

import {
  optionsImage,
  optionsVideo,
  optionsFile,
} from '@/components/Actives/util/optionsDropzone';

import config from '@/config/config';
import { sha256 } from 'js-sha256';
let tus = require('tus-js-client');

export default {
  components: {
    vue2Dropzone,
  },
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
      default: null,
    },

    categorySelected: {
      type: Number || String,
      default: null,
    },
  },
  emits:[
    'closeModal'
  ],
  data() {
    return {
      fileToUpload: {
        filename: '',
        description: '',
      },
      val: {
        title: null,
        description: null,
        link: null,
        file: null,
      },
      options: {
        image: optionsImage,
        video: optionsVideo,
        file: optionsFile,
      },
      filesSuccesB: 0,
      filesProcessingB: 0,
      filessB: 0,
      dropzoneOptions: {
        url: config.data.apiUrl + '/media/upload/videoResource',
        thumbnailWidth: 150,
        maxFilesize: 4000, // MB
        timeout: 9999999, // Miliseconds
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('user-token'),
          'Cache-Control': '',
          video: null,
        },
        addRemoveLinks: true,
      },
      amount: 0,
      id_video: null,
    };
  },
  computed: {
    open: {
      // getter
      get: function () {
        return this.active;
      },
      // setter
      set: function () {
        this.closeModal();
      },
    },
    saludo: function () {
      if (this.active) {
        if (this.typeFile == 'video') {
          //console.log("Saludo :Vv",this.item);
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.dropzoneOptions.headers.video = this.item.id;
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.amount = 0;
        }
      }
      return '';
    },
  },
  watch: {
    item: function (newVal) {
      if (this.isEmpty) {
        this.val = {
          title: newVal.filename,
          description: newVal.description,
          link: newVal.url,
          file: null,
        };
      }
    },
  },
  mounted() {
    console.log(this.item);
    this.isVideoConsult();
  },
  methods: {
    isVideoConsult() {
      //console.log(this.item,"Mountend mediaFile");
    },
    isEmpty(e) {
      return !_.isEmpty(e);
    },
    notifyAlert(message = '', type = 'success') {
      this.$notify({
        type,
        verticalAlign: 'bottom',
        horizontalAlign: 'left',
        message,
      });
    },
    getPartnersByCategory() {
      this.$store.dispatch(
        MEDIA_GET_PARTNERS_BY_CATEGORIE,
        this.filterForCategory,
      );
    },
    isValidLink() {
      if (!this.val.link) return false;
      const expression =
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/;
      const regex = new RegExp(expression);
      return this.val.link.match(regex);
    },
    send() {
      console.log('cat', this.categorySelected);
      // if used link
      if (this.typeFile != 'video') {
        if (this.val.link) {
          if (this.isValidLink()) {
            const formData = this.parseDataIsLink();
            this.$store.dispatch(MEDIA_UPLOAD_FILE, formData).then((resp) => {
              this.fileToUpload = {
                ...resp,
                filename: this.val.title,
                description: this.val.description,
              };
              this.$store
                .dispatch(MEDIA_CREATE_FILE, this.fileToUpload)
                .then(() => {
                  this.notifyAlert('Enlace subido');
                  if (this.filterForCategory) this.getPartnersByCategory();
                })
                .catch(() =>
                  this.notifyAlert(
                    'Error al subir el enlace. Por favor, vuelve a intentar',
                    'danger',
                  ),
                )
                .finally(() => this.closeModal());
            });
          } else {
            this.val.link = null;
            this.notifyAlert(
              'El enlace tiene que seguir el ejemplo indicado',
              'danger',
            );
            return;
          }
        }
      } else if (this.typeFile == 'video') {
        this.edit();
      }

      // if uploading status its success
      if (!this.val.link && this.typeFile != 'video') {
        this.fileToUpload.filename = this.val.title;
        this.fileToUpload.description = this.val.description;
        this.$store
          .dispatch(MEDIA_CREATE_FILE, this.fileToUpload)
          .then(() => {
            this.notifyAlert('Archivo subido');
            if (this.filterForCategory) this.getPartnersByCategory();
          })
          .catch(() => this.notifyAlert('Error al subir archivo', 'danger'))
          .finally(() => this.closeModal());
      }
    },
    edit() {
      console.log('Edit :v ');
      if (!this.val.link && !this.val.file) {
        this.notifyAlert(
          'Para actualizar este archivo tienes que agregar un archivo o enlace',
          'danger',
        );
        return;
      }

      if (this.val.link && !this.isValidLink()) {
        this.notifyAlert(
          'El enlace tiene que seguir el ejemplo indicado',
          'danger',
        );
        return;
      }

      this.fileToUpload.filename = this.val.title;
      this.fileToUpload.description = this.val.description;
      this.fileToUpload.id = this.id_video ? this.id_video : this.item.id;
      this.fileToUpload.type = this.typeFile;
      console.log(this.fileToUpload);

      if (this.val.link === this.item.url && this.typeFile !== 'video') {
        //console.log(this.fileToUpload,'Hola 1 no video',this.typeFile);

        this.$store
          .dispatch(MEDIA_UPDATE_FILE, this.fileToUpload)
          .then(() => {
            this.notifyAlert('Se ha actualizado el archivo');
            if (this.fileToUpload.hash) this.deleteFile();
          })
          .catch(() =>
            this.notifyAlert(
              'No se pudo actualizar el archivo. Vuelve a intentar',
              'danger',
            ),
          )
          .finally(() => this.closeModal());
      }

      if (this.val.link !== this.item.url && this.typeFile !== 'video') {
        //console.log(this.fileToUpload,"Hola 2 no video",this.typeFile);

        const formData = this.parseDataIsLink();
        this.$store.dispatch(MEDIA_UPLOAD_FILE, formData).then((res) => {
          res.id = this.item.id;
          this.$store
            .dispatch(MEDIA_UPDATE_FILE, res)
            .then(() => {
              this.notifyAlert('Se ha actualizado el archivo');
              this.deleteFile();
            })
            .catch(() =>
              this.notifyAlert(
                'No se pudo actualizar el archivo. Vuelve a intentar',
                'danger',
              ),
            )
            .finally(() => this.closeModal());
        });
      }
      if (this.typeFile === 'video') {
        this.$store
          .dispatch(MEDIA_UPDATE_FILE, this.fileToUpload)
          .then(() => {
            this.notifyAlert('Se ha actualizado el archivo');
            if (this.fileToUpload.hash) this.deleteFile();
          })
          .catch(() =>
            this.notifyAlert(
              'No se pudo actualizar el archivo. Vuelve a intentar',
              'danger',
            ),
          )
          .finally(() => this.closeModal());
      }
      this.id_video = null;
    },
    parseDataIsLink() {
      const formData = new FormData();
      formData.append('url', this.val.link);
      formData.append('title', this.val.title);
      formData.append('description', this.val.description);
      formData.append('type', this.typeFile);
      formData.append('category', this.categorySelected);
      return formData;
    },
    deleteFile() {
      this.$store
        .dispatch(MEDIA_DELETE_FILE_IN_CDN, this.item)
        .catch(() =>
          this.notifyAlert(
            'No se pudo eliminar el archivo de la nube',
            'danger',
          ),
        );
    },
    closeModal() {
      this.$emit('closeModal', 'mediaFiles');
      this.val = {
        title: null,
        description: null,
        link: null,
        file: null,
      };
      this.amount = 0;
      this.fileToUpload = {};
      this.$refs['files'].removeAllFiles();
    },

    // dropzone
    errorFileEvent(ref) {
      this.$refs[ref].removeAllFiles();
      this.notifyAlert(
        'El archivo no cumple con la extension requerida. Agrega otro',
        'danger',
      );
      this.val.file = null;
    },
    fileAdded(file) {
      if (!file.manuallyAdded) {
        this.val.file = file;
      }
      if (file.manuallyAdded) {
        if (file.previewElement) {
          const ref = file.previewElement.querySelector('[data-dz-thumbnail]');
          ref.src = this.val.url;
        }
      }
    },
    removeFile() {
      if (this.fileToUpload.hash) {
        this.$store
          .dispatch(MEDIA_DELETE_FILE_IN_CDN, this.fileToUpload)
          .finally(() => (this.amount = 0));
      }
      this.fileToUpload = {};
      this.amount = 0;
    },
    successUploadFileLesson(file, response) {
      this.fileToUpload = response.file;
      let ref;

      if (file.previewElement) {
        ref = file.previewElement.querySelector('[data-dz-thumbnail]');
        ref.src = response.file.url;
      }
    },
    sendingFileEvent(file, xhr, formData) {
      formData.append('title', this.val.title);
      formData.append('description', this.val.description);
      formData.append('type', this.typeFile);
      formData.append('category', this.categorySelected);
    },
    uploadProgress(total) {
      this.amount = total;
    },

    //dropzone bunny
    fileAddedB() {
      console.log('hola2');
      this.filessB = this.filessB + 1;
      //this.amount=this.amount+1;
    },
    sendingEventB() {
      console.log('Aqui empezo a enviar el archivo');
      this.amount = 0;
    },
    successUploadB(file, response) {
      console.log('hola6');
      console.log('success', response);
      this.filesSuccesB = this.filesSuccesB + 1;

      var ref;

      if (file.previewElement) {
        ref = file.previewElement.querySelector('[data-dz-thumbnail]');
        ref.src = response.video.thumbnail;
        console.log(ref);
      }

      // console.log(file);
      // console.log(response.video.thumbnail);
      // console.log("Sucess");
      this.$store
        .dispatch(VIDEO_LIST_VIDEOS)
        .then(() => {
          this.$refs.videodropzone.removeFile(true);
        })
        .catch(() => {});

      if (this.filesSuccesB == this.files) {
        this.filessB = 0;
        this.filesProcessingB = 0;
        this.filesSuccesB = 0;
      }
      var thas = this;
      this.val.link = response.video.url;
      this.id_video = response.video.id;
      let upload = new tus.Upload(file, {
        endpoint: 'https://video.bunnycdn.com/tusupload',
        headers: {
          AuthorizationSignature: sha256(
            '36805' +
              '08d7c26c-2f59-4de9-b3172dd59982-9aaf-4861' +
              '99999999999' +
              `${response.video.hash}`,
          ), // SHA256 signature (library_id + api_key + expiration_time + video_id)
          AuthorizationExpire: 99999999999, // Expiration time as in the signature,
          VideoId: `${response.video.hash}`, // The guid of a previously created video object through the Create Video API call
          LibraryId: 36805,
        },
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          filename: file.name,
          filetype: file.type,
        },
        onError: function (error) {
          console.log('Failed because: ' + error);
        },
        onProgress: function (bytesUploaded, bytesTotal) {
          var percentage = parseInt(
            ((bytesUploaded / bytesTotal) * 100).toFixed(2),
          );
          console.log(bytesUploaded, bytesTotal, percentage + '%');
          thas.amount = percentage;
          //that.messagesProcess="cargando en bunny "+percentage + "%";
        },
        onSuccess: function () {
          console.log('Download %s from %s', upload.file.name, upload.url);
          console.log(upload);
          thas.$notify({
            verticalAlign: 'top',
            horizontalAlign: 'right',
            message: 'Se ha actualizado con éxito lección de video',
          });
          thas.$refs.dropzone.enable();
          console.log(thas.$refs.dropzone);
          //thas.replayVideo();
        },
      });

      // Check if there are any previous uploads to continue.
      upload.findPreviousUploads().then(function (previousUploads) {
        // Found previous uploads so we select the first one.
        if (previousUploads.length) {
          upload.resumeFromPreviousUpload(previousUploads[0]);
        }

        // Start the upload
        upload.start();
      });
    },
    completeEventB(response) {
      console.log('hola5');
      console.log('Evento complete:');
      if (response.status != 'error') {
        console.log('lo logro');
      } else {
        console.log('no lo logro');
      }
    },
    uploadProgressB(totaluploadprogress) {
      console.log('hola4');
      //this.amount = totaluploadprogress
      console.log(totaluploadprogress);
    },
    canceledFileB(response) {
      console.log('canceled');
      console.log(response.status);
      if (response.status == 'canceled') {
        this.filessB = this.filessB - 1;
        this.filesProcessingB = this.filesProcessingB - 1;
      }
    },
    removedFileB(archivo) {
      console.log('removido');
      console.log(archivo.status);
      if (archivo.status == 'queued') {
        this.filessB = this.filessB - 1;
      } else if (archivo.status == 'error') {
        this.filessB = this.filessB - 1;
        this.filesProcessingB = this.filesProcessingB - 1;
      }
    },
    processingFileB(file) {
      console.log('processingFile');
      console.log(file);
      this.filesProcessingB = this.filesProcessingB + 1;
    },
    completeMultipleFileB(respuesta) {
      console.log('hola todas pasaron');
      console.log(respuesta);
    },
  },
};
</script>
