<template>
  <div>
    <md-dialog :md-active.sync="open" class="modal-agg-category">
      <span class="material-icons close text-right" @click="closeModal"
        >close</span
      >
      <div class="body-modal-agg-category">
        <md-dialog-title>AGREGAR A CATEGORIA</md-dialog-title>

        <div class="form-group mt-3">
          <Multiselect
            v-model="val"
            deselect-label="Remover categoria"
            track-by="title"
            label="title"
            placeholder="Selecciona categoria"
            :options="getMediaCategories"
            :searchable="true"
            :allow-empty="true"
          >
            <template slot="singleLabel" slot-scope="{ option }"
              ><strong style="vertical-align: -webkit-baseline-middle">{{
                option.title
              }}</strong></template
            >
             <template #noResult>
              <div>Sin resultados</div>
            </template>
          </Multiselect>
        </div>

        <md-dialog-actions>
          <md-button
            class="md-button md-primary md-theme-default"
            @click="closeModal"
            >CANCELAR</md-button
          >
          <md-button class="md-button md-primary md-theme-default" @click="send"
            >GUARDAR</md-button
          >
        </md-dialog-actions>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';
import {
  MEDIA_ASSIGN_CATEGORY_TO_FILE,
  MEDIA_GET_CATEGORY,
  MEDIA_GET_PARTNERS_BY_CATEGORIE,
} from '@/store/actions/media';

export default {
  components: {
    Multiselect,
  },
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      val: null,
    };
  },
  methods: {
    notifyAlert(message = '', type = 'success') {
      this.$notify({
        type,
        verticalAlign: 'bottom',
        horizontalAlign: 'left',
        message,
      });
    },
    closeModal() {
      this.$emit('closeModal', 'assignCategory');
      this.val = null;
    },
    send() {
      const file = {
        media_id: this.item.id,
        category_id: this.val ? this.val.id : null,
      };

      this.$store
        .dispatch(MEDIA_ASSIGN_CATEGORY_TO_FILE, file)
        .then(() => {
          this.notifyAlert('Se ha asignado la categoria con éxito');

          if (this.val?.id) {
            this.$store.dispatch(MEDIA_GET_PARTNERS_BY_CATEGORIE, this.val.id);
          }
        })
        .catch(() =>
          this.notifyAlert('No se ha podido asignar categoria', 'danger'),
        )
        .finally(() => this.closeModal());
    },
  },
  watch: {
    item: function (newVal) {
      this.$store
        .dispatch(MEDIA_GET_CATEGORY, {
          id: newVal.media_category_id ? newVal.media_category_id : null,
        })
        .then((resp) => (this.val = resp.data.category))
        .catch((err) => (this.val = null));
    },
  },
  computed: {
    open: {
      // getter
      get: function () {
        return this.active;
      },
      // setter
      set: function () {
        this.closeModal();
      },
    },
    ...mapGetters(['getMediaCategories']),
  },
};
</script>
