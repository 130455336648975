<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4"></div>
      <!-- Card stats -->
      <div class="row"></div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="col-12 card mb-4 px-0">
        <!--        <div class="col-12 card mb-4">-->
        <!--          <general-metrics-->
        <!--            :end-date="endDate"-->
        <!--            :selected-item="selectedItem"-->
        <!--            :start-date="startDate"-->
        <!--            :compare-dates="compareDates"-->
        <!--            @change-selected-item="changeSelectedItem"-->
        <!--          />-->
        <!--        </div>-->
        <div class="row form-group align-items-center">
          <div
            class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-6 reports-select"
          >
            <label
for="card-name"
class="text-maquillate2"
              >Cursos disponibles</label
            >

            <multiselect
              v-model="selectedCourse"
              :options="getAllCourses.items"
              :loading="isLoadingCourses"
              :searchable="true"
              :close-on-select="true"
              :show-labels="true"
              placeholder="Seleccione curso"
              deselect-label="x"
              selected-label="x"
              track-by="name"
              label="name"
              :allow-empty="true"
            >
              <template #singleLabel="{ option }">
                <strong>{{ option.name }}</strong>
              </template>
              <template #noResult>
                <div>Sin resultados</div>
              </template>
            </multiselect>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-4">
            <div
              class="px-0 d-flex pr-3-selects header-dashboard-mobile-home align-items-center justify-content-between"
            >
              <div
                class="d-flex flex-wrap align-items-center header-dashboard-status"
              >
                <div class="d-flex">
                  <div
                    :class="{ 'bg-icon-chart': !isSelectChart }"
                    class="p-2__home cursor border-custom-left"
                    @click="isSelectChart = false"
                  >
                    <md-icon :class="{ 'text-white': !isSelectChart }"
                      >equalizer
                    </md-icon>
                  </div>
                  <div
                    :class="{ 'bg-icon-chart': isSelectChart }"
                    class="p-2__home cursor border-custom-right"
                    @click="isSelectChart = true"
                  >
                    <md-icon :class="{ 'text-white': isSelectChart }"
                      >list
                    </md-icon>
                  </div>
                </div>
                <div class="pl-3 d-flex align-items-center size-text-calendary">
                  <DatePicker @change-date="changeDates" />

                  <small
                    class="pl-2 text-muted size-13 mobile-status"
                    style="color: black !important"
                  >
                    <span
                      >Comparado con:
                      {{
                        parseDate(
                          compareDates.compareStartDate,
                          compareDates.compareEndDate,
                        )
                      }}</span
                    >
                  </small>
                </div>
              </div>
              <div class="view-all-header-dashboard">
                <div class="input-group size-text-calendary">
                  <router-link
                    v-if="selectedItem === metricOptions.PROFIT_METRIC"
                    class="custom-select view-all text-decoration-none text-gray font-size-view-all pr-0"
                    to="/admin/facturas"
                  >
                    <span class="font-weight-bolder color-text-maquillate"
                      >VER MÁS</span
                    >
                  </router-link>
                  <span
                    class="material-icons arrow-forward color-text-maquillate"
                  >
                    arrow_forward_ios
                  </span>
                </div>
              </div>
            </div>
            <div v-if="isSelectedChart" id="tab-home">
              <div class="row">
                <card
                  class="col-12 last-orders px-4 pt-4 table-container my-0"
                  header-classes="bg-transparent"
                  type="white"
                >
                  <div v-if="selectedItem === metricOptions.PROFIT_METRIC">
                    <el-table
                      v-if="!isLoading"
                      :data="getMetricInvoices"
                      class="table-responsive table-flush order-last-list mb-4"
                      header-row-class-name="thead-light"
                    >
                      <el-table-column
                        label="Fecha"
                        min-width="65px"
                        prop="date"
                        sortable
                      >
                        <template #default="{ row }">
                          <div class="media align-items-center">
                            <div class="media-body">
                              <span class="font-weight-400 name mb-0">{{
                                utcDate(row.created_at)
                              }}</span>
                            </div>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="N° Factura"
                        min-width="120px"
                        prop="code"
                        sortable
                      >
                        <template #default="{ row }">
                          <div class="media align-items-center">
                            <span class="status font-weight-400 name">{{
                              row.transaction_invoice_id
                            }}</span>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="N° Orden"
                        min-width="120px"
                        prop="id"
                        sortable
                      >
                        <template #default="{ row }">
                          <div class="media align-items-center">
                            <span class="status font-weight-400 name">{{
                              row.transaction_id
                            }}</span>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        class="text-center"
                        label="Descripción"
                        min-width="100px"
                        prop="description"
                        sortable
                      >
                        <template #default="{ row }">
                          <div
                            v-for="(order, index) in row.orders"
                            :key="index"
                          >
                            <router-link
                              :to="`/cursos/curso/${order.course.id}/${order.course.slug_name}`"
                              style="
                                color: #b7227e !important;
                                text-decoration: none !important;
                                font-weight: bold;
                              "
                            >
                              {{ order.course.name }}
                            </router-link>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Usuario"
                        min-width="90px"
                        prop="enable"
                        sortable
                      >
                        <template #default="{ row }">
                          <div class="media align-items-center">
                            <div class="media-body order-date">
                              <span class="font-weight-400 name mb-0">
                                {{
                                  row.user && row.user.name ? row.user.name : ''
                                }}
                              </span>
                            </div>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Monto"
                        min-width="60px"
                        prop="expiration"
                        sortable
                      >
                        <template #default="{ row }">
                          <div class="media align-items-center">
                            <div class="media-body order-amount">
                              <span class="font-weight-400 name mb-0">
                                ${{ row.profit }}
                              </span>
                            </div>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Factura"
                        min-width="70px"
                        prop="pdf"
                      >
                        <template #default="{ row }">
                          <div class="avatar-group">
                            <a
                              :href="
                                downloadInvoice(row.transaction_invoice_id)
                              "
                              class="style-avatar"
                              target="_blank"
                            >
                              <i class="ni ni-single-copy-04"></i>
                            </a>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                    <el-table
                      v-else
                      class="table-responsive table-flush"
                      header-row-class-name="thead-light"
                      :data="[1, 2, 3, 4]"
                    >
                      <el-table-column
                        label="Fecha"
                        min-width="150px"
                        prop="title"
                        sortable
                      >
                        <template #default>
                          <skeleton-square
                            :box-properties="{
                              height: '16px',
                              top: '10px',
                            }"
                          />
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="N° Factura"
                        min-width="100px"
                        prop="title"
                      >
                        <template #default>
                          <skeleton-square
                            :box-properties="{
                              height: '16px',
                              top: '10px',
                            }"
                          />
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="N° Orden"
                        min-width="100px"
                        prop="title"
                        sortable
                      >
                        <template #default>
                          <skeleton-square
                            :box-properties="{
                              height: '16px',
                              top: '10px',
                            }"
                          />
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Descripción"
                        min-width="100px"
                        prop="title"
                        sortable
                      >
                        <template #default>
                          <skeleton-square
                            :box-properties="{
                              height: '16px',
                              top: '10px',
                            }"
                          />
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Usuario"
                        min-width="100px"
                        prop="title"
                        sortable
                      >
                        <template #default>
                          <skeleton-square
                            :box-properties="{
                              height: '16px',
                              top: '10px',
                            }"
                          />
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Monto"
                        min-width="100px"
                        prop="title"
                        sortable
                      >
                        <template #default>
                          <skeleton-square
                            :box-properties="{
                              height: '16px',
                              top: '10px',
                            }"
                          />
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Factura"
                        min-width="150px"
                        prop="edit"
                        class-name="option"
                      >
                        <template #default>
                          <div>
                            <skeleton-square
                              :box-properties="{
                                height: '16px',
                                top: '10px',
                                width: '40px',
                              }"
                            />
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </card>
              </div>
            </div>
            <div v-else id="tab-pages">
              <div class="row">
                <card
                  class="col-12 custom-chart px-0 pt-4"
                  header-classes="bg-transparent"
                >
                  <div v-if="selectedItem === metricOptions.PROFIT_METRIC">
                    <line-chart
                      :key="indexChart"
                      ref="bigChart"
                      :chart-data="
                        getMetrics(
                          getMetricInvoices,
                          'profit',
                          'Ingresos netos',
                        )
                      "
                      :extra-options="extraOptions"
                      :height="350"
                      :width="950"
                    />
                  </div>
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Charts-->
    <div class="container-fluid mt--6">
      <div class="row">
        <card
          class="col-12 last-orders px-0 my-0"
          type="white"
          header-classes="bg-transparent"
        >
          <h2 class="text-uppercase">Ultimas ordenes</h2>
          <el-table
            class="table-responsive table-flush order-last-list"
            header-row-class-name="thead-light"
            :data="getMetricOrders"
          >
            <el-table-column
              label="Información de cliente"
              min-width="150px"
              prop="code"
              sortable
            >
              <template #default="{ row }">
                <div class="media align-items-center">
                  <div class="media-body">
                    <span class="font-weight-500 name mb-0 text-sm">
                      <div class="customer-info">
                        <h2>{{ row.user.name }}</h2>
                        <span>{{ row.user.email }}</span>
                      </div>
                    </span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Curso" prop="enable" sortable>
              <template #default="{ row }">
                <div class="media align-items-center">
                  <div class="media-body order-date">
                    <span class="font-weight-500 name mb-0 text-sm">
                      {{ row.course.name }}
                    </span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Fecha" prop="enable" sortable>
              <template #default="{ row }">
                <div class="media align-items-center">
                  <div class="media-body order-date">
                    <span class="font-weight-500 name mb-0 text-sm">
                      {{ utcDate(row.created_at) }}
                    </span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Cupón aplicado" prop="discoun" sortable>
              <template #default="{ row }">
                <div class="media align-items-center">
                  <div class="media-body order-coupon">
                    <span v-if="row.coupon_id">
                      {{ row.coupon.code }}
                    </span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Monto" prop="expiration" sortable>
              <template #default="{ row }">
                <div class="media align-items-center">
                  <div class="media-body order-amount">
                    <span class="font-weight-500 name mb-0 text-sm">
                      ${{ row.invoices.profit }}
                    </span>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </card>

        <div class="col-12 px-0">
          <card
            class="col-12 custom-chart px-0 mt-0 mb-0 course-most-sell"
            type="white"
            header-classes="bg-transparent"
          >
            <h2 class="mt-0 text-uppercase">Cursos más Vendidos</h2>

            <el-table
              class="table-responsive table-flush order-last-list"
              header-row-class-name="thead-light"
              :data="getMetricCourses"
            >
              <el-table-column label="Nombre del curso" prop="code" sortable>
                <template #default="{ row }">
                  <div class="media align-items-center">
                    <div class="media-body">
                      <span class="font-weight-500 name mb-0 text-sm">
                        <div class="customer-info">
                          <h2>{{ row.name }}</h2>
                        </div>
                      </span>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Cantidad" prop="enable" sortable>
                <template #default="{ row }">
                  <div class="media align-items-center">
                    <div class="media-body order-date">
                      <span class="font-weight-500 name mb-0 text-sm">
                        {{ row.orders_count }}
                      </span>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Total" prop="enable" sortable>
                <template #default="{ row }">
                  <div class="media align-items-center">
                    <div class="media-body order-date">
                      <span class="font-weight-500 name mb-0 text-sm">
                        ${{ getCourseOrderTotal(row).toFixed(2) }}
                      </span>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </card>
        </div>
        <div class="col-12 px-0">
          <card
            class="col-12 custom-chart px-0 mt-0 mb-0 popular-clients"
            type="white"
            header-classes="bg-transparent"
          >
            <h2 class="text-uppercase">Clientes Populares</h2>

            <el-table
              class="table-responsive table-flush order-last-list"
              header-row-class-name="thead-light"
              :data="getMetricUserOrders"
            >
              <el-table-column label="Cliente" prop="code" sortable>
                <template #default="{ row }">
                  <div class="media align-items-center">
                    <div class="media-body">
                      <span class="font-weight-500 name mb-0 text-sm">
                        <div class="customer-info">
                          <h2>{{ row.name }}</h2>
                          <span>{{ row.email }}</span>
                        </div>
                      </span>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Cantidad" prop="enable" sortable>
                <template #default="{ row }">
                  <div class="media align-items-center">
                    <div class="media-body order-date">
                      <span class="font-weight-500 name mb-0 text-sm">
                        {{ row.orders_count }}
                      </span>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Total" prop="enable" sortable>
                <template #default="{ row }">
                  <div class="media align-items-center">
                    <div class="media-body order-date">
                      <span class="font-weight-500 name mb-0 text-sm">
                        ${{ getCourseOrderTotal(row).toFixed(2) }}
                      </span>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import LineChart from '@/components/Charts/LineChart';
import * as chartConfigs from '@/components/Charts/config';
import rangesDatePicker, { localeData } from '@/util/rangeDatePicker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

import moment from 'moment';

moment.locale('es-mx');

import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from 'element-ui';
import { FETCH_COURSES } from '../../store/actions/course';
import SkeletonSquare from '../../components/Skeleton/components/SkeletonSquare.vue';
import {
  FETCH_METRIC_COURSES,
  FETCH_METRIC_INVOICES,
  FETCH_METRIC_ORDERS,
  FETCH_METRIC_USER_ORDERS,
} from '../../store/actions/metric';
import { comparerDateInit, getPeriodData } from '../../store/utils/metrics';
import { PROFIT_METRIC } from '../../util/constants';
import tooltipQuestion from '@/util/tooltipQuestion';
import config from '@/config/config';
import DatePicker from '@/components/DatePicker';

//moment.locale('es-mx')

export default {
  name: 'AdminReports',
  components: {
    SkeletonSquare,
    // GeneralMetrics,
    Multiselect,
    LineChart,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    DatePicker,
  },
  data() {
    return {
      rangesDatePicker,
      localeData,
      courses: null,
      selectedCourse: undefined,
      isLoadingOrders: true,

      bigLineChart: {
        allData: [],
        activeIndex: null,
        chartData: {},
      },
      dateRange: {
        startDate: null,
        endDate: null,
      },
      rangedName: 'Este mes',
      isRanged: true,

      selectedItem: PROFIT_METRIC,
      metricOptions: {
        PROFIT_METRIC: PROFIT_METRIC,
      },

      tooltipQuestion,
      startDate: moment().startOf('month').format(),
      endDate: moment().format(),

      compareDates: comparerDateInit(),

      ranged: 'last',
      isLoading: true,

      isSelectResume: 0, // 0 -> ingresos, 1 -> nuevos clientes, 2 -> nuevos socios
      confirmDelete: false,
      idCourse: null,
      isSelectChart: false,
      indexChart: 0,
      isLoadingCourses: false,
      isLoadingUserOrders: false,
      isLoadingMetricCourses: false,
      dataChart: {
        labels: [],
        values: {
          revenue: [],
          user: [],
          partner: [],
        },
      },
      increaseValues: {
        profit_revenue: 0,
      },
      extraOptions: chartConfigs.blueChartOptions,
    };
  },
  computed: {
    ...mapGetters([
      'getAllCourses',
      'getMetricInvoices',
      'getMetricOrders',
      'getMetricCourses',
      'getMetricUserOrders',
    ]),
    isSelectedChart() {
      return this.isSelectChart;
    },
  },
  watch: {
    selectedCourse: function (newValue) {
      if (newValue) {
        this.fetchMetricInvoices({
          start_date: this.startDate,
          end_date: this.endDate,
          course_id: newValue.id,
        });
      } else {
        this.fetchMetricInvoices();
      }
    },
  },
  mounted() {
    this.fetchMetricInvoices();
    this.fetchMetricOrders();
    this.fetchMetricCourses();
    this.fetchMetricUserOrders();
    this.fetchCourses().then(() => (this.isLoadingCourses = false));
  },
  methods: {
    fetchCourses(
      filter = {
        search: this.searchValue,
        per_page: 20,
        order_by: this.sortType,
      },
    ) {
      return this.$store.dispatch(FETCH_COURSES, filter);
    },
    fetchMetricInvoices(
      filter = {
        start_date: this.startDate,
        end_date: this.endDate,
        course_id: this.selectedCourse ? this.selectedCourse.id : '',
      },
    ) {
      this.$store
        .dispatch(FETCH_METRIC_INVOICES, filter)
        .then(() => (this.isLoading = false));
    },
    fetchMetricOrders(
      filter = {
        start_date: this.startDate,
        end_date: this.endDate,
      },
    ) {
      this.$store
        .dispatch(FETCH_METRIC_ORDERS, filter)
        .then(() => (this.isLoadingOrders = false));
    },
    fetchMetricUserOrders(
      filter = {
        start_date: this.startDate,
        end_date: this.endDate,
      },
    ) {
      this.$store
        .dispatch(FETCH_METRIC_USER_ORDERS, filter)
        .then(() => (this.isLoadingUserOrders = false));
    },
    fetchMetricCourses(
      filter = {
        start_date: this.startDate,
        end_date: this.endDate,
      },
    ) {
      this.$store
        .dispatch(FETCH_METRIC_COURSES, filter)
        .then(() => (this.isLoadingMetricCourses = false));
    },

    getMetrics(metricInvoices = [], field = '', label = '') {
      console.log('metricInvoices', metricInvoices);
      const { labels, data } = getPeriodData(
        metricInvoices,
        field,
        this.startDate,
        this.endDate,
      );
      return {
        labels,
        datasets: [
          {
            label,
            data,
          },
        ],
      };
    },
    async clearDateRnge() {
      this.isLoading = true;
      await this.updateDateRange(this.dateOnLoad);
      this.isLoading = false;
    },

    utcDate(date) {
      return moment(date).format('l');
    },
    changeDates({ startDate, endDate, ranged }) {
      this.startDate = moment(startDate).format();
      this.endDate = moment(endDate).format();

      console.log({ startDate, endDate, ranged });

      if (ranged.amount === 0 && ranged.unitOfTime === 'day') {
        // range is today
        this.compareDates = {
          compareStartDate: moment(startDate)
            .subtract(1, ranged.unitOfTime)
            .format(),
          compareEndDate: moment(endDate)
            .subtract(1, ranged.unitOfTime)
            .format(),
        };
      } else if (ranged.amount === 1 && ranged.unitOfTime === 'day') {
        // yesterday
        this.compareDates = {
          compareStartDate: moment(startDate)
            .subtract(ranged.amount, ranged.unitOfTime)
            .format(),
          compareEndDate: moment(endDate)
            .subtract(ranged.amount, ranged.unitOfTime)
            .format(),
        };
      } else if (ranged.amount === 0 && ranged.unitOfTime === 'month') {
        // this month
        this.compareDates = {
          compareStartDate: moment(startDate)
            .subtract(1, ranged.unitOfTime)
            .format(),
          compareEndDate: moment(endDate)
            .subtract(1, ranged.unitOfTime)
            .format(),
        };
      } else if (ranged.amount === 0 && ranged.unitOfTime === 'year') {
        // this year

        this.compareDates = {
          compareStartDate: moment(startDate)
            .subtract(1, ranged.unitOfTime)
            .format(),
          compareEndDate: moment(endDate)
            .subtract(1, ranged.unitOfTime)
            .format(),
        };
      } else if (ranged === 'last') {
        const diff = moment(endDate).diff(moment(startDate), 'days');
        this.compareDates = {
          compareStartDate: moment(startDate).subtract(diff, 'days').format(),
          compareEndDate: moment(endDate).subtract(diff, 'days').format(),
        };
      } else if (ranged.amount === 1 && ranged.unitOfTime === 'month') {
        this.compareDates = {
          compareStartDate: moment(startDate)
            .subtract(ranged.amount, ranged.unitOfTime)
            .format(),
          compareEndDate: moment(endDate)
            .subtract(ranged.amount, ranged.unitOfTime)
            .format(),
        };
      } else if (ranged.amount === 1 && ranged.unitOfTime === 'year') {
        this.compareDates = {
          compareStartDate: moment(startDate)
            .subtract(ranged.amount, ranged.unitOfTime)
            .format(),
          compareEndDate: moment(endDate)
            .subtract(ranged.amount, ranged.unitOfTime)
            .format(),
        };
      } else {
        const diff = moment(endDate).diff(moment(startDate), 'days');
        this.compareDates = {
          compareStartDate: moment(startDate)
            .subtract(diff, ranged.unitOfTime)
            .format(),
          compareEndDate: moment(endDate)
            .subtract(diff, ranged.unitOfTime)
            .format(),
        };
      }

      this.fetchMetricInvoices({
        start_date: this.startDate,
        end_date: this.endDate,
        course_id: this.selectedCourse ? this.selectedCourse.id : '',
      });
      this.fetchMetricOrders({
        start_date: this.startDate,
        end_date: this.endDate,
      });
      this.fetchMetricCourses({
        start_date: this.startDate,
        end_date: this.endDate,
      });
      this.fetchMetricUserOrders({
        start_date: this.startDate,
        end_date: this.endDate,
      });
    },
    downloadInvoice(invoiceId) {
      return `${config.data.apiUrl}/invoice/download/${invoiceId}`;
    },
    parseDate(startDate, endDate) {
      return `${moment(startDate).format('ll')} - ${moment(endDate).format(
        'll',
      )}`;
    },
    changeSelectedItem(selectedItem) {
      this.selectedItem = selectedItem;
    },
    getCourseOrderTotal(course) {
      const orders = course && course.orders ? course.orders : [];

      return orders.reduce((acc, curr) => {
        if (curr && curr.invoices) {
          return acc + curr.invoices.profit;
        }
        return acc;
      }, 0);
    },
  },
};
</script>
<style>
.last-orders .card-body {
  padding: 0.5rem 1.5rem !important;
}

.popular-clients .card-body {
  padding: 0.5rem 1.5rem !important;
}

.course-most-sell .card-body {
  padding: 0.5rem 1.5rem !important;
}

.footer {
  padding: 0px !important;
}

.reports-select .multiselect__select {
  height: 50px !important;
}

.multiselect__option--selected:after {
  background: #b5016d !important;
  color: #fff;
  font-weight: 600;
  padding-right: 20px;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #b5016d !important;
  color: #fff;
}
.course-most-sell .card-body {
  padding: 0.5rem 1rem !important;
}
.popular-clients .card-body {
  padding: 0.5rem 1rem !important;
}
.last-orders .card-body {
  padding: 0.5rem 1rem !important;
}
</style>
