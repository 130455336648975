import moment from 'moment';

export const comparerDateInit = (unitOfTime = 'month') => {
  const startDate = moment().startOf(unitOfTime).format();
  const endDate = moment().format();
  const diff = moment(endDate).diff(moment(startDate), 'days');

  const compareStartDate = moment(startDate).subtract(1, unitOfTime);
  const compareEndDate = moment(compareStartDate).add(diff, 'days');

  return {
    compareStartDate,
    compareEndDate,
  };
};

export const getPeriodData = (
  dataMetric = [],
  field = '',
  startDate,
  endDate,
) => {
  const labels = [];
  const diffDays = moment(endDate)
    .diff(moment(startDate), 'days', true)
    .toFixed(0);
  const diffMonths = moment(endDate)
    .diff(moment(startDate), 'months', true)
    .toFixed(0);
  const diffWeeks = moment(endDate)
    .diff(moment(startDate), 'weeks', true)
    .toFixed(0);
  const data = [];

  console.log('diffWeeks', diffMonths);
  console.log('diffWeeks', diffWeeks);
  console.log('diffWeeks', diffWeeks);

  if (diffDays > 0 && diffDays < 1) {
    const diffTime = moment(moment(startDate).endOf('day')).diff(
      moment(startDate).startOf('day'),
      'hour',
      true,
    );

    for (let i = 0; i < diffTime; i++) {
      const startDateOfTime = moment(startDate)
        .startOf('day')
        .add(i, 'hour')
        .format('HH:mm');
      const endDateOfTime = moment(startDate)
        .startOf('day')
        .add(i + 1, 'hour')
        .format('HH:mm');
      const time = `${startDateOfTime} - ${endDateOfTime}`;
      const revenue = dataMetric.reduce((acc, curr) => {
        const revenueStartDateOfTime = moment(curr.created_at)
          .startOf('hour')
          .format('HH:mm');
        const revenueEndDateOfTime = moment(curr.created_at)
          .endOf('hour')
          .add(1, 'second')
          .format('HH:mm');
        const revenueDate = `${revenueStartDateOfTime} - ${revenueEndDateOfTime}`;
        const count = field ? curr[field] : 1;

        return revenueDate === time ? count + acc : acc;
      }, 0);
      labels.push(time);
      data.push(revenue.toFixed(2));
    }
  } else if (diffDays > 0 && diffDays <= 31) {
    for (let i = 0; i < diffDays; i++) {
      const date = moment(startDate).add(i, 'day').format('DD/MM');
      const revenue = dataMetric.reduce((acc, curr) => {
        const count = field ? curr[field] : 1;
        return moment(curr.created_at).format('DD/MM') === date
          ? count + acc
          : acc;
      }, 0);
      labels.push(date);
      data.push(revenue.toFixed(2));
    }
  } else if (diffMonths > 0 && diffMonths <= 3) {
    for (let i = 0; i <= diffWeeks; i++) {
      const startDateOfWeek = moment(startDate)
        .add(i, 'week')
        .startOf('week')
        .format('DD/MM');
      const endDateOfWeek = moment(startDate)
        .add(i, 'week')
        .endOf('week')
        .format('DD/MM');

      const date = `${startDateOfWeek} - ${endDateOfWeek}`;
      const revenue = dataMetric.reduce((acc, curr) => {
        const revenueStartDateOfWeek = moment(curr.created_at)
          .startOf('week')
          .format('DD/MM');
        const revenueEndDateOfWeek = moment(curr.created_at)
          .endOf('week')
          .format('DD/MM');
        const revenueDate = `${revenueStartDateOfWeek} - ${revenueEndDateOfWeek}`;

        const count = field ? curr[field] : 1;

        return revenueDate === date ? count + acc : acc;
      }, 0);

      labels.push(date);
      data.push(revenue.toFixed(2));
    }
  } else {
    for (let i = 0; i <= diffMonths; i++) {
      const startDateOfMonth = moment(startDate)
        .add(i, 'month')
        .startOf('month')
        .format('DD/MM');
      const endDateOfMonth = moment(startDate)
        .add(i, 'month')
        .endOf('month')
        .format('DD/MM');
      const dateMonth = `${startDateOfMonth} - ${endDateOfMonth}`;
      const revenue = dataMetric.reduce((acc, curr) => {
        const revenueStartDateOfMonth = moment(curr.created_at)
          .startOf('month')
          .format('DD/MM');
        const revenueEndDateOfMonth = moment(curr.created_at)
          .endOf('month')
          .format('DD/MM');
        const revenueDate = `${revenueStartDateOfMonth} - ${revenueEndDateOfMonth}`;

        const count = field ? curr[field] : 1;

        return revenueDate === dateMonth ? count + acc : acc;
      }, 0);

      labels.push(dateMonth);
      data.push(revenue.toFixed(2));
    }
  }

  return { labels, data };
};
