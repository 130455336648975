<template>
  <div>
    <base-header class="pb-6" @update-values="clearDateRnge">
      <div class="row align-items-center py-4"></div>
      <!-- Card stats -->
      <div class="row"></div>
    </base-header>
    <!--Charts-->
    <div class="container-fluid mt--6">
      <div class="col-12 card mb-4">
        <div class="col-12 card mb-4 pl-0">
          <general-metrics
            :end-date="endDate"
            :selected-item="selectedItem"
            :start-date="startDate"
            :compare-dates="compareDates"
            @change-selected-item="changeSelectedItem"
          />
        </div>
        <div class="row">
          <div class="col-12 pb-4 pl-0 pr-0 mr-0">
            <div
              class="d-flex pl-0 pb-3 header-dashboard-mobile-home align-items-center justify-content-between"
            >
              <div
                class="d-flex flex-wrap align-items-center header-dashboard-status"
              >
                <div class="d-flex">
                  <div
                    :class="{ 'bg-icon-chart': isSelectChart }"
                    class="p-2__home border-custom-left cursor"
                    @click="isSelectChart = true"
                  >
                    <md-icon :class="{ 'text-white': isSelectChart }"
                      >list
                    </md-icon>
                  </div>
                  <div
                    :class="{ 'bg-icon-chart': !isSelectChart }"
                    class="p-2__home border-custom-right cursor"
                    @click="isSelectChart = false"
                  >
                    <md-icon :class="{ 'text-white': !isSelectChart }"
                      >equalizer
                    </md-icon>
                  </div>
                </div>
                <div class="pl-3 d-flex align-items-center size-text-calendary">
                  <DatePicker @change-date="changeDates" />

                  <small
                    class="pl-2 text-muted size-13 mobile-status"
                    style="color: black !important"
                  >
                    <span
                      >Comparado con:
                      {{
                        parseDate(
                          compareDates.compareStartDate,
                          compareDates.compareEndDate,
                        )
                      }}</span
                    >
                  </small>
                </div>
              </div>
              <div class="view-all-header-dashboard">
                <div class="input-group size-text-calendary">
                  <router-link
                    v-if="selectedItem === metricOptions.PROFIT_METRIC"
                    class="custom-select view-all text-decoration-none text-gray font-size-view-all"
                    to="/admin/facturas"
                  >
                    <span class="font-weight-bolder color-text-maquillate"
                      >VER MÁS</span
                    >
                  </router-link>
                  <router-link
                    v-if="selectedItem === metricOptions.USER_METRIC"
                    class="custom-select view-all text-decoration-none text-gray font-size-view-all"
                    to="/admin/usuarios"
                  >
                    <span class="font-weight-bolder color-text-maquillate"
                      >VER MÁS</span
                    >
                  </router-link>
                  <span
                    class="material-icons arrow-forward color-text-maquillate"
                  >
                    arrow_forward_ios
                  </span>
                </div>
              </div>
            </div>
            <div v-if="isSelectedChart" id="tab-home">
              <div class="row">
                <card
                  class="col-12 table-container px-0"
                  header-classes="bg-transparent"
                  type="white"
                >
                  <div v-if="selectedItem === metricOptions.PROFIT_METRIC">
                    <el-table
                      v-if="!isLoading"
                      :data="getMetricInvoices"
                      class="table-responsive table-flush order-last-list mb-4"
                      header-row-class-name="thead-light"
                    >
                      <el-table-column
                        label="Fecha"
                        min-width="65px"
                        prop="date"
                        sortable
                      >
                        <template #default="{ row }">
                          <div class="media align-items-center">
                            <div class="media-body">
                              <span class="font-weight-400 name mb-0">{{
                                utcDate(row.created_at)
                              }}</span>
                            </div>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="N° Factura"
                        min-width="120px"
                        prop="code"
                        sortable
                      >
                        <template #default="{ row }">
                          <div class="media align-items-center">
                            <span class="status font-weight-400 name">{{
                              row.transaction_invoice_id
                            }}</span>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="N° Orden"
                        min-width="120px"
                        prop="id"
                        sortable
                      >
                        <template #default="{ row }">
                          <div class="media align-items-center">
                            <span class="status font-weight-400 name">{{
                              row.transaction_id
                            }}</span>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        class="text-center"
                        label="Descripción"
                        min-width="100px"
                        prop="description"
                        sortable
                      >
                        <template #default="{ row }">
                          <div
                            v-for="(order, index) in row.orders"
                            :key="index"
                          >
                            <router-link
                              :to="`/cursos/curso/${order.course.id}/${order.course.slug_name}`"
                              style="
                                color: #b7227e !important;
                                text-decoration: none !important;
                                font-weight: bold;
                              "
                            >
                              {{ order.course.name }}
                            </router-link>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Usuario"
                        min-width="90px"
                        prop="enable"
                        sortable
                      >
                        <template #default="{ row }">
                          <div class="media align-items-center">
                            <div class="media-body order-date">
                              <span class="font-weight-400 name mb-0">
                                {{
                                  row.user && row.user.name ? row.user.name : ''
                                }}
                              </span>
                            </div>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Monto"
                        min-width="60px"
                        prop="expiration"
                        sortable
                      >
                        <template #default="{ row }">
                          <div class="media align-items-center">
                            <div class="media-body order-amount">
                              <span class="font-weight-400 name mb-0">
                                ${{ row.profit }}
                              </span>
                            </div>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Factura"
                        min-width="70px"
                        prop="pdf"
                      >
                        <template #default="{ row }">
                          <div class="avatar-group">
                            <a
                              :href="
                                downloadInvoice(row.transaction_invoice_id)
                              "
                              class="style-avatar"
                              target="_blank"
                            >
                              <i class="ni ni-single-copy-04"></i>
                            </a>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                    <el-table
                      v-else
                      class="table-responsive table-flush"
                      header-row-class-name="thead-light"
                      :data="[1, 2, 3, 4]"
                    >
                      <el-table-column
                        label="Fecha"
                        min-width="150px"
                        prop="title"
                        sortable
                      >
                        <template #default>
                          <skeleton-square
                            :box-properties="{
                              height: '16px',
                              top: '10px',
                            }"
                          />
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="N° Factura"
                        min-width="100px"
                        prop="title"
                      >
                        <template #default>
                          <skeleton-square
                            :box-properties="{
                              height: '16px',
                              top: '10px',
                            }"
                          />
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="N° Orden"
                        min-width="100px"
                        prop="title"
                        sortable
                      >
                        <template #default>
                          <skeleton-square
                            :box-properties="{
                              height: '16px',
                              top: '10px',
                            }"
                          />
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Descripción"
                        min-width="100px"
                        prop="title"
                        sortable
                      >
                        <template #default>
                          <skeleton-square
                            :box-properties="{
                              height: '16px',
                              top: '10px',
                            }"
                          />
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Usuario"
                        min-width="100px"
                        prop="title"
                        sortable
                      >
                        <template #default>
                          <skeleton-square
                            :box-properties="{
                              height: '16px',
                              top: '10px',
                            }"
                          />
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Monto"
                        min-width="100px"
                        prop="title"
                        sortable
                      >
                        <template #default>
                          <skeleton-square
                            :box-properties="{
                              height: '16px',
                              top: '10px',
                            }"
                          />
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Factura"
                        min-width="150px"
                        prop="edit"
                        class-name="option"
                      >
                        <template #default>
                          <div>
                            <skeleton-square
                              :box-properties="{
                                height: '16px',
                                top: '10px',
                                width: '40px',
                              }"
                            />
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>

                  <TableUsers
                    v-if="selectedItem === metricOptions.USER_METRIC"
                    :items="getMetricUsers"
                  />
                </card>
              </div>
            </div>
            <div v-else id="tab-pages">
              <div class="row">
                <card
                  class="col-12 custom-chart px-2 pt-5"
                  header-classes="bg-transparent"
                >
                  <div v-if="selectedItem === metricOptions.PROFIT_METRIC">
                    <line-chart
                      :key="indexChart"
                      ref="bigChart"
                      :chart-data="
                        getMetrics(
                          getMetricInvoices,
                          'profit',
                          'Ingresos netos',
                        )
                      "
                      :extra-options="extraOptions"
                      :height="350"
                      :width="950"
                    />
                  </div>
                  <div v-if="selectedItem === metricOptions.USER_METRIC">
                    <line-chart
                      :key="indexChart"
                      ref="bigChart"
                      :chart-data="getMetrics(getMetricUsers, '', 'Usuarios')"
                      :extra-options="extraOptions"
                      :height="350"
                      :width="950"
                    />
                  </div>
                </card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Table,
  TableColumn,
} from 'element-ui';

import tooltipQuestion from '@/util/tooltipQuestion';
import { TableUsers } from '@/components/Admin/Dashboard';
import DatePicker from '@/components/DatePicker';
import LineChart from '@/components/Charts/LineChart';
import * as chartConfigs from '@/components/Charts/config';
import {
  FETCH_METRIC_INVOICES,
  FETCH_METRIC_USERS,
} from '../../../store/actions/metric';
import { comparerDateInit, getPeriodData } from '../../../store/utils/metrics';
import config from '@/config/config';
import GeneralMetrics from './components/GeneralMetrics';
import { USER_METRIC, PROFIT_METRIC } from '../../../util/constants';
import SkeletonSquare from '../../../components/Skeleton/components/SkeletonSquare.vue';

export default {
  name: 'DashboardAdmin',
  components: {
    SkeletonSquare,
    GeneralMetrics,
    //Multiselect,
    LineChart,
    //BarChart,
    [DropdownMenu.name]: DropdownMenu,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    TableUsers,
    DatePicker,
  },

  data() {
    return {
      selectedItem: PROFIT_METRIC,
      metricOptions: {
        PROFIT_METRIC: PROFIT_METRIC,
        USER_METRIC: USER_METRIC,
      },

      tooltipQuestion,
      startDate: moment().startOf('month').format(),
      endDate: moment().format(),

      compareDates: comparerDateInit(),

      ranged: 'last',
      isLoading: true,

      isSelectResume: 0, // 0 -> ingresos, 1 -> nuevos clientes, 2 -> nuevos socios
      confirmDelete: false,
      idCourse: null,
      isSelectChart: true,
      indexChart: 0,
      isLoadingCourses: false,
      dataChart: {
        labels: [],
        values: {
          revenue: [],
          user: [],
          partner: [],
        },
      },
      increaseValues: {
        profit_revenue: 0,
      },
      extraOptions: chartConfigs.blueChartOptions,
    };
  },
  computed: {
    ...mapGetters(['getMetricUsers', 'getMetricGeneral', 'getMetricInvoices']),
    isSelectedChart() {
      return this.isSelectChart;
    },
  },
  mounted() {
    this.fetchMetricInvoices();
    this.fetchMetricUsers();
  },
  methods: {
    fetchMetricInvoices(
      filter = {
        start_date: this.startDate,
        end_date: this.endDate,
      },
    ) {
      this.$store
        .dispatch(FETCH_METRIC_INVOICES, filter)
        .then(() => (this.isLoading = false));
    },
    fetchMetricUsers(
      filter = {
        start_date: this.startDate,
        end_date: this.endDate,
      },
    ) {
      this.$store.dispatch(FETCH_METRIC_USERS, filter).then(() => {
        console.log('test');
        this.isLoading = false;
      });
    },
    getMetrics(metricInvoices = [], field = '', label = '') {
      console.log('metricInvoices', metricInvoices);
      const { labels, data } = getPeriodData(
        metricInvoices,
        field,
        this.startDate,
        this.endDate,
      );
      return {
        labels,
        datasets: [
          {
            label,
            data,
          },
        ],
      };
    },
    async clearDateRnge() {
      this.isLoading = true;
      await this.updateDateRange(this.dateOnLoad);
      this.isLoading = false;
    },

    utcDate(date) {
      return moment(date).format('l');
    },
    changeDates({ startDate, endDate, ranged }) {
      this.startDate = moment(startDate).format();
      this.endDate = moment(endDate).format();

      console.log({ startDate, endDate, ranged });

      if (ranged.amount === 0 && ranged.unitOfTime === 'day') {
        // range is today
        this.compareDates = {
          compareStartDate: moment(startDate)
            .subtract(1, ranged.unitOfTime)
            .format(),
          compareEndDate: moment(endDate)
            .subtract(1, ranged.unitOfTime)
            .format(),
        };
      } else if (ranged.amount === 1 && ranged.unitOfTime === 'day') {
        // yesterday
        this.compareDates = {
          compareStartDate: moment(startDate)
            .subtract(ranged.amount, ranged.unitOfTime)
            .format(),
          compareEndDate: moment(endDate)
            .subtract(ranged.amount, ranged.unitOfTime)
            .format(),
        };
      } else if (ranged.amount === 0 && ranged.unitOfTime === 'month') {
        // this month
        this.compareDates = {
          compareStartDate: moment(startDate)
            .subtract(1, ranged.unitOfTime)
            .format(),
          compareEndDate: moment(endDate)
            .subtract(1, ranged.unitOfTime)
            .format(),
        };
      } else if (ranged.amount === 0 && ranged.unitOfTime === 'year') {
        // this year

        this.compareDates = {
          compareStartDate: moment(startDate)
            .subtract(1, ranged.unitOfTime)
            .format(),
          compareEndDate: moment(endDate)
            .subtract(1, ranged.unitOfTime)
            .format(),
        };
      } else if (ranged === 'last') {
        const diff = moment(endDate).diff(moment(startDate), 'days');
        this.compareDates = {
          compareStartDate: moment(startDate).subtract(diff, 'days').format(),
          compareEndDate: moment(endDate).subtract(diff, 'days').format(),
        };
      } else if (ranged.amount === 1 && ranged.unitOfTime === 'month') {
        this.compareDates = {
          compareStartDate: moment(startDate)
            .subtract(ranged.amount, ranged.unitOfTime)
            .format(),
          compareEndDate: moment(endDate)
            .subtract(ranged.amount, ranged.unitOfTime)
            .format(),
        };
      } else if (ranged.amount === 1 && ranged.unitOfTime === 'year') {
        this.compareDates = {
          compareStartDate: moment(startDate)
            .subtract(ranged.amount, ranged.unitOfTime)
            .format(),
          compareEndDate: moment(endDate)
            .subtract(ranged.amount, ranged.unitOfTime)
            .format(),
        };
      } else {
        const diff = moment(endDate).diff(moment(startDate), 'days');
        console.log('diff', diff);
        this.compareDates = {
          compareStartDate: moment(startDate)
            .subtract(diff, ranged.unitOfTime)
            .format(),
          compareEndDate: moment(endDate)
            .subtract(diff, ranged.unitOfTime)
            .format(),
        };
      }

      this.fetchMetricInvoices({
        start_date: this.startDate,
        end_date: this.endDate,
      });
      this.fetchMetricUsers({
        start_date: this.startDate,
        end_date: this.endDate,
      });
    },
    downloadInvoice(invoiceId) {
      return `${config.data.apiUrl}/invoice/download/${invoiceId}`;
    },
    parseDate(startDate, endDate) {
      return `${moment(startDate).format('ll')} - ${moment(endDate).format(
        'll',
      )}`;
    },
    changeSelectedItem(selectedItem) {
      this.selectedItem = selectedItem;
    },
  },
};
</script>

<style>
.table-flush .cell span {
  font-size: 13px;
}

.media-body span {
  font-size: 13px;
}

.option > .cell {
  display: flex;
  justify-content: end;
}
.header-dashboard-mobile-home {
  padding-right: 15px !important;
}
</style>
