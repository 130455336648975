<template>
  <div class="container-fluid text-default">
    <div class="row">
      <div class="col">
        <div v-if="!loading && currentOrder">
          <card>
            <div class="row no-gutters">
              <div
                class="col-mobile-orderDetails col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4 my-1"
              >
                <p class="font-weight-bold mb-1">Usuario:</p>
                <span>{{ currentOrder.user.name }}</span>
              </div>
              <div
                class="col-mobile-orderDetails col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4 my-1"
              >
                <p class="font-weight-bold mb-1">Dia:</p>
                <span>{{ getDateFormat(currentOrder.created_at) }}</span>
              </div>
              <div
                class="col-mobile-orderDetails col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4 my-1"
              >
                <p class="font-weight-bold mb-1">Metodo:</p>
                <span v-if="currentOrder.payment_method == 'creditcard'"
                  >Tarjeta de Credito</span
                >
                <span v-else>Paypal</span>
              </div>
              <div
                class="col-mobile-orderDetails col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4 my-1"
              >
                <p class="font-weight-bold mb-1">Estado:</p>
                <span v-if="currentOrder.statusPartner == pending">
                  Pendiente
                </span>
                <span v-else> Completado </span>
              </div>
            </div>
            <h3 class="mt-3">Codigo de Orden: {{ currentOrder.order_id }}</h3>
            <h3 class="mt-3">
              Codigo de Invoice: {{ currentOrder.invoice_id }}
            </h3>
            <p><b>Curso</b></p>
            <div class="d-flex justify-content-between">
              <span class="pb-0 pt-1">{{ currentOrder.course.name }}</span>
              <span class="pb-0 pt-1"
                >${{ currentOrder.total.toFixed(2) }}</span
              >
            </div>
            <div class="d-flex justify-content-between pb-0 m-0">
              <small class="pb-0 m-0">Comisión del pago</small>
              <small class="pb-0 m-0"
                >-${{
                  (currentOrder.total - currentOrder.profit).toFixed(2)
                }}</small
              >
            </div>
            <hr />
            <div class="d-flex justify-content-end">
              <p>${{ currentOrder.profit }}</p>
            </div>
          </card>
        </div>
        <div v-else>Cargando</div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from 'axios';
import config from '@/config/config';
import moment from 'moment';
export default {
  data() {
    return {
      currentOrder: null,
      loading: false,
    };
  },
  async mounted() {
    let { id } = this.$route.params;
    if (id) {
      this.loading = true;
      await Axios.get(config.data.apiUrl + '/admin/order/getByOrder/' + id, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
        },
      })
        .then((resp) => {
          console.log(resp);
          this.currentOrder = resp.data;
          console.log(this.currentOrder);
          this.loading = false;
        })
        .catch((err) => console.log(err));
    }
  },
  methods: {
    getDateFormat(date) {
      return moment(date).format('ll');
    },
  },
};
</script>

<style></style>
