import category from './Category';
import categoryToFile from './CategoryToFile';
import mediaFiles from './MediaFiles';
import mediaUrl from './MediaUrl';
import shareCategoryPartner from './ShareCategoryPartner';
import shareFilePartner from './ShareFilePartner';
import viewFile from './ViewFile';

export const CategoryToFile = categoryToFile;
export const MediaFiles = mediaFiles;
export const MediaUrl = mediaUrl;
export const ShareCategoryPartner = shareCategoryPartner;
export const ShareFilePartner = shareFilePartner;
export const ViewFile = viewFile;
export const Category = category;
