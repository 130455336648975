<template>
  <div class="row">
    <div
      class="col-md-4 pl-0"
      @click="changeSelectedItem(metricOptions.PROFIT_METRIC)"
    >
      <card
        :class="
          selectedItem === metricOptions.PROFIT_METRIC
            ? 'border-color-bottom-select'
            : 'border-color-bottom'
        "
        class="general-stats-container__box cursor details-containers-admin px-0"
        header-classes="bg-transparent border-left px-0"
      >
        <template #header>
          <div class="row general-stats-container__box__header">
            <h2
              v-tooltip.right="{
                content: tooltipQuestion[metricOptions.PROFIT_METRIC],
              }"
              class="color-text color-dark-hover pl-2"
            >
              Ingresos
              <i class="fas fa-question-circle"></i>
            </h2>
          </div>
        </template>

        <h2 class="border-left px-0">
          <span class="pl-2">
            $ {{ Number.parseFloat(getMetricGeneral.profit.total).toFixed(2) }}
          </span>
        </h2>

        <span
          :class="{
            'chart_increase chart_increase--positive pl-2':
              getMetricGeneral.profit.variation > 0,
            'chart_increase chart_increase-negative pl-2':
              getMetricGeneral.profit.variation < 0,
            'chart_increase chart_increase--neutro pl-2':
              getMetricGeneral.profit.variation === 0,
          }"
        >
          <span
            v-if="getMetricGeneral.profit.variation"
            class="background-icon"
          >
            <i
              v-if="getMetricGeneral.profit.variation > 0"
              class="fa fa-arrow-up ml-0 color-arrow-green pr-2"
              aria-hidden="true"
            ></i>
            <i
              v-else-if="getMetricGeneral.profit.variation < 0"
              class="fa fa-arrow-down color-arrow-red pr-2"
              aria-hidden="true"
            ></i>
          </span>
          <span
            v-if="getMetricGeneral.profit.variation > 0"
            class="color-text color-arrow-green"
            >{{ getMetricGeneral.profit.variation.toFixed(2) }}%</span
          >
          <span
            v-else-if="getMetricGeneral.profit.variation < 0"
            class="color-text color-arrow-red"
            >{{ getMetricGeneral.profit.variation.toFixed(2) }}%</span
          >
          <span
v-else
class="color-text"
            >{{ getMetricGeneral.profit.variation.toFixed(2) }}%</span
          >
        </span>
      </card>
    </div>
    <div
      class="col-md-4 pl-0"
      @click="changeSelectedItem(metricOptions.USER_METRIC)"
    >
      <card
        :class="
          selectedItem === metricOptions.USER_METRIC
            ? 'border-color-bottom-select'
            : 'border-color-bottom'
        "
        class="general-stats-container__box cursor details-containers-admin"
        header-classes="bg-transparent border-left px-0"
      >
        <template #header>
          <div class="row general-stats-container__box__header">
            <h2
              v-tooltip.right="{
                content: tooltipQuestion[metricOptions.USER_METRIC],
              }"
              class="pl-2 color-text color-dark-hover detail-labels"
            >
              Nuevos usuarios
              <i class="fas fa-question-circle"></i>
            </h2>
          </div>
        </template>

        <h2 class="border-left px-0">
          <span class="pl-2">
            {{ Number.parseFloat(getMetricGeneral.user.total) }}
          </span>
        </h2>

        <span
          :class="{
            'chart_increase chart_increase--positive pl-2':
              getMetricGeneral.user.variation > 0,
            'chart_increase chart_increase-negative pl-2':
              getMetricGeneral.user.variation < 0,
            'chart_increase chart_increase--neutro pl-2':
              getMetricGeneral.user.variation === 0,
          }"
        >
          <span v-if="getMetricGeneral.user.variation" class="background-icon">
            <i
              v-if="getMetricGeneral.user.variation > 0"
              class="fa fa-arrow-up ml-0 color-arrow-green pr-2"
              aria-hidden="true"
            ></i>
            <i
              v-else-if="getMetricGeneral.user.variation < 0"
              class="fa fa-arrow-down color-arrow-red pr-2"
              aria-hidden="true"
            ></i>
          </span>
          <span
            v-if="getMetricGeneral.user.variation > 0"
            class="color-text color-arrow-green"
            >{{ getMetricGeneral.user.variation.toFixed(2) }}%</span
          >
          <span
            v-else-if="getMetricGeneral.user.variation < 0"
            class="color-text color-arrow-red"
            >{{ getMetricGeneral.user.variation.toFixed(2) }}%</span
          >
          <span
v-else
class="color-text"
            >{{ getMetricGeneral.user.variation.toFixed(2) }}%</span
          >
        </span>
      </card>
    </div>
    <div class="col-md-4"></div>
  </div>
</template>

<script>
import tooltipQuestion from '@/util/tooltipQuestion';
import { mapGetters } from 'vuex';
import { FETCH_METRIC_GENERAL } from '../../../../store/actions/metric';
import moment from 'moment';
import { PROFIT_METRIC, USER_METRIC } from '../../../../util/constants';
import { comparerDateInit } from '../../../../store/utils/metrics';

export default {
  name: 'GeneralMetrics',

  props: {
    selectedItem: {
      type: String,
      default: 'profit',
    },
    startDate: {
      type: String,
      default: moment().format().toString(),
    },
    compareDates: {
      type: Object,
      default: () => {
        return comparerDateInit();
      },
    },
    endDate: {
      type: String,
      default: moment().format().toString(),
    },
  },
  emits: ['change-selected-item'],
  data() {
    return {
      tooltipQuestion,
      metricOptions: {
        PROFIT_METRIC: PROFIT_METRIC,
        USER_METRIC: USER_METRIC,
      },
    };
  },
  computed: {
    ...mapGetters(['getMetricGeneral']),
  },
  watch: {
    startDate: function () {
      this.fetchGeneralMetrics();
    },
  },
  mounted() {
    this.fetchGeneralMetrics();
  },

  methods: {
    fetchGeneralMetrics(
      filter = {
        start_date: moment(this.startDate).startOf('day').format(),
        end_date: moment(this.endDate).endOf('day').format(),
        compare_start_date: moment(this.compareDates.compareStartDate)
          .startOf('day')
          .format(),
        compare_end_date: moment(this.compareDates.compareEndDate)
          .endOf('day')
          .format(),
      },
    ) {
      this.$store
        .dispatch(FETCH_METRIC_GENERAL, filter)
        .then(() => (this.isLoading = false));
    },
    changeSelectedItem(selectedItem) {
      console.log('selectedItem', selectedItem);
      this.$emit('change-selected-item', selectedItem);
    },
  },
};
</script>

<style scoped>
.color-arrow-red {
  color: #ed4545 !important;
}

.color-arrow-green {
  color: #55bf2f !important;
}

.general-stats-container__box .card-body h2 {
  padding-left: 0px !important;
}
</style>
