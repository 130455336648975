<template>
  <div>
    <md-dialog :md-active.sync="open" class="modalEdit modal-img-archive">
      <div class="w-100 position-fixed mobile-cancel">
        <span class="material-icons close text-right" @click="closeModal"
          >close</span
        >
      </div>
      <div class="body-modal-img-archive py-5 modal-body">
        <md-dialog-title class="md-title pb-3 add-category text-left">
          <span class="text-uppercase" v-if="item">{{ item.filename }}</span>
        </md-dialog-title>

        <div class="dialog-body">
          <div v-if="item">
            <md-card class="shadow-none">
              <md-card-content class="p-0 mt-4">
                <md-card-media>
                  <div
                    class="selected__file__container__preview details-video-assets"
                    v-if="item.type === 'video'"
                  >
                    <video-player
                      class="videoplayer d-flex justify-content-center"
                      ref="videoplayer"
                      :options="options"
                      :key="item.id"
                    />
                  </div>

                  <div
                    class="selected__file__container__preview d-flex justify-content-center"
                    v-if="item.type == 'image'"
                  >
                    <img
                      :src="item.url"
                      alt=""
                      class="selected__file__container__preview"
                    />
                  </div>
                  <div
                    class="selected__file__container__preview"
                    v-if="item.type === 'file'"
                  >
                    <VueDocPreview
                      v-if="
                        item.extension !== 'pdf' && item.extension !== 'txt'
                      "
                      class="vuePreviewDoc"
                      :value="item.url"
                      type="office"
                    />

                    <embed
                      class="embed"
                      v-else
                      :src="item.url"
                      width="100%"
                      height="300"
                    />
                  </div>
                </md-card-media>
              </md-card-content>
              <md-card-content
                v-if="item.description !== 'null'"
                class="mt-4 px-0"
                style="text-align: start"
              >
                <h4 class="text-bold text-left">DESCRIPCIÓN</h4>
                {{ item.description }}
              </md-card-content>
            </md-card>
          </div>
        </div>

        <md-dialog-actions class="modal-footer">
          <md-button class="btn-primary w-auto" @click="closeModal">
            CERRAR
          </md-button>
          <md-button class="btn-primary ml-0 w-auto d-none"> </md-button>
        </md-dialog-actions>
      </div>
    </md-dialog>
    <!---------------->
    <!-- <modal
      @close="closeModal"
      size="lg"
      :show.sync="open"
    >
      <template v-slot:header>
        <h5 v-if="item" class="modal-title" id="exampleModalLabel">
          {{ item.filename }} hola
        </h5>
      </template>
      <div>
        <div v-if="item">
          <md-card>
            <md-card-content>
              <md-card-media>
                <div
                  class="selected__file__container__preview d-flex justify-content-center"
                  v-if="item.type === 'video'"
                >
                  <video-player
                    v-if="item.url"
                    class="videoplayer d-flex justify-content-center"
                    ref="videoplayer"
                    :options="options"
                    :key="item.id"
                  />
                </div>

                <div
                  class="selected__file__container__preview d-flex justify-content-center"
                  v-if="item.type == 'image'"
                >
                  <img
                    :src="item.url"
                    alt=""
                    class="selected__file__container__preview"
                  />
                </div>
                <div
                  class="selected__file__container__preview"
                  v-if="item.type === 'file'"
                >
                  <VueDocPreview
                    v-if="
                      item.extension !== 'pdf' &&
                        item.extension !== 'txt'
                    "
                    class="vuePreviewDoc"
                    :value="item.url"
                    type="office"
                  />

                  <embed
                    class="embed"
                    v-else
                    :src="item.url"
                    width="100%"
                    height="300"
                  />
                </div>
              </md-card-media>
            </md-card-content>
            <md-card-content v-if="item.description !== 'null'">
              {{ item.description }}
            </md-card-content>
          </md-card>
        </div>
      </div>
      <template v-slot:footer>
        <base-button type="secondary" @click="closeModal">Cerrar</base-button>
      </template>
    </modal> -->
  </div>
</template>

<script>
import VideoPlayer from '@/components/VideoPlayer.vue';
import VueDocPreview from 'vue-doc-preview';

import 'video.js/dist/video-js.css';

export default {
  components: {
    VideoPlayer,
    VueDocPreview,
  },
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      options: {
        /*{
        autoplay: false,
        controls: true,
        sources: [
          {
            withCredentials: false,
            type: "application/x-mpegURL",
            src: ""
          }
        ],
        //playbackRates: [1, 1.5, 2],
        fluid: true,
        aspectRatio: "16:9",
        poster: null,
        hls: true,
        plugins: {
          airPlay: {
            addButtonToControlBar: true // defaults to `true`
          }
        }
      }*/
        autoplay: false,
        controls: true,
        sources: [
          {
            src: '',
            type: 'application/x-mpegURL',
            //type:'video/mp4',
          },
        ],
        //playbackRates: [1, 1.5, 2],
        fluid: true,
        aspectRatio: '16:9',
        poster: null,
        hls: true,
        plugins: {
          airPlay: {
            addButtonToControlBar: true, // defaults to `true`
          },
        },
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit('closeModal', 'selectedFile');
      this.val = null;
    },
    mostrarDatos() {
      console.log(this.item);
      if (this.item.type == 'video') {
        this.options.sources[0].src = this.item.url;
      }
      console.log(this.options.sources[0].src);
    },
  },
  computed: {
    open: {
      // getter
      get: function () {
        this.mostrarDatos();
        return this.active;
      },
      // setter
      set: function () {
        this.closeModal();
      },
    },
  },
};
</script>
