import config from '@/config/config';

export const optionsImage = {
  url: config.data.apiUrl + '/media/upload',
  thumbnailWidth: 150,
  maxFilesize: 3000, // MB
  timeout: 9999999, // Miliseconds
  addRemoveLinks: true,
  acceptedFiles: '.jpg, .jpeg, .png, .gif',
  headers: {
    Authorization: 'Bearer ' + localStorage.getItem('user-token'),
    'Cache-Control': '',
  },
};

export const optionsVideo = {
  url: config.data.apiUrl + '/media/upload',
  thumbnailWidth: 150,
  maxFilesize: 3000, // MB
  timeout: 9999999, // Miliseconds
  addRemoveLinks: true,
  acceptedFiles: '.mov, .mpeg4, .mp4, .m4v, .avi, .wmv, .mpegps, .flv',
  headers: {
    Authorization: 'Bearer ' + localStorage.getItem('user-token'),
    'Cache-Control': '',
  },
};

export const optionsFile = {
  url: config.data.apiUrl + '/media/upload',
  thumbnailWidth: 150,
  maxFilesize: 3000, // MB
  timeout: 9999999, // Miliseconds
  addRemoveLinks: true,
  acceptedFiles: '.pdf, .doc, .docx, .ppt, .pptx, .xlsx, .xls, .txt',
  headers: {
    Authorization: 'Bearer ' + localStorage.getItem('user-token'),
    'Cache-Control': '',
  },
};

export const optionsVideoD = {
  //resive parametro para mandar video para el back de maquillate
  url: config.data.apiUrl + '/admin/courses/upload/video',
  thumbnailWidth: 150,
  maxFilesize: 3000, // MB
  timeout: 9999999, // Miliseconds
  headers: {
    Authorization: 'Bearer ' + localStorage.getItem('user-token'),
    'Cache-Control': '',
    video: null,
  },
  addRemoveLinks: true,
};
