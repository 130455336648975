<template>
  <div class="container-fluid">
    <div>
      <div
        class="col-12 row justify-content-between container-partners__search container-video-media-form pr-0 pl-0 ml-0 mb-3"
      >
        <div
          class="col-xs-mobile col-6 col-sm-6 col-md-7 col-lg-7 col-xl-7 pl-0"
        >
          <form class="filter-flex">
            <i class="fas fa-search fa-flip-horizontal"></i>
            <input
              v-model="type"
              class="form-control filter-input__category inputFilter me-2"
              placeholder="Buscar por nombre"
              type="text"
            />

            <div class="md-layout md-gutter">
              <div class="md-layout-item" style="padding: 0 !important">
                <md-field class="sort-box" style="">
                  <md-select
                    v-model="type"
                    class="custom-select filter inputFilter sort-select sort-portrait md-filter-input md-sort-select"
                  >
                    <md-option hidden selected value="">Filtrar por</md-option>
                    <md-option
                      value="pdf, doc, docx, ppt, pptx, xls, xlsx, txt"
                    >
                      Archivos
                    </md-option>
                    <md-option value="jpg, jpeg, png, gif">Imagen</md-option>
                    <md-option
                      value="mov, mpeg4, mp4, m4v, avi, wmv, mpegps, flv"
                      >Video</md-option
                    >
                    <md-option value="url">Enlace</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
          </form>
        </div>
        <div class="col-assets-mobile col-sm-3 col-md-3 col-lg-3 col-xl-3 pr-0">
          <div
            v-if="getProfile.roles.includes('admin')"
            class="float-right-button"
          >
            <base-dropdown title-classes="btn mr-0 btn-course py-2 mb-0">
              <template #title>
                <div class="d-flex align-items-center">
                  <span>AGREGAR</span>
                  <span class="material-icons expand-more"> expand_more </span>
                </div>
              </template>
              <a class="dropdown-item" @click="openCategory()">Categoria</a>
              <a
class="dropdown-item"
@click.prevent="openMedia('file')"
                >Archivo</a
              >
              <a
class="dropdown-item"
@click.prevent="openMedia('image')"
                >Imagen</a
              >
              <a
class="dropdown-item"
@click.prevent="openMedia('video')"
                >Video</a
              >
              <a
class="dropdown-item"
@click.prevent="openMediaUrl({})"
                >Enlace</a
              >
            </base-dropdown>
          </div>
        </div>
      </div>
    </div>
    <!--    END HEADER SECTION-->

    <!--    SECTION FILES AND CATEGORIES-->
    <div class="container-fluid container-activos__body">
      <div class="media-activos">
        <div
          v-if="getMediaCategories && getProfile.roles.includes('admin')"
          class="category-grid"
        >
          <a
            v-if="getMediaCategories && filterForCategory !== null"
            class="category-grid__single d-flex align-items-center"
            @click="changeCategoryFiles(null)"
          >
            <i class="fas fa-folder mr-2"></i> Volver
          </a>
          <div
            v-for="category in categoriesToShow"
            :key="category.id"
            class="category-grid__single d-flex justify-content-between align-items-center"
            :class="!isSelected ? 'border-card' : 'categoriChoice border-card'"
          >
            <a
              :class="isSelected && 'categoriChoice'"
              @click="changeCategoryFiles(category)"
            >
              <i
                :class="
                  !isSelected
                    ? 'fas fa-folder mr-2'
                    : 'fas fa-folder-open categoriChoice mr-2'
                "
              ></i>
              <span
                :class="isSelected && 'categoriChoice'"
                class="name-category"
                >{{ category.title }}</span
              >
            </a>
            <md-menu
              v-if="getProfile.roles.includes('admin')"
              md-direction="bottom-end"
              :md-close-on-click="closeOnClick"
              :md-close-on-select="closeOnSelect"
            >
              <i class="fas fa-align-justify" md-menu-trigger></i>

              <md-menu-content>
                <md-menu-item @click="openShareCategoryToPartner(category)">
                  Compartir categoria completa.
                </md-menu-item>
                <md-menu-item @click="openCategory(category)"
                  >Editar nombre de categoria.</md-menu-item
                >
                <md-menu-item @click="handleDelete('deleteCategory', category)"
                  >Eliminar categoria.</md-menu-item
                >
              </md-menu-content>
            </md-menu>
          </div>
        </div>

        <div
          v-if="getProfile.roles.includes('admin')"
          class="form-group col-12 mt-3"
        >
          <div class="files-grid">
            <div
              v-for="file in archivos"
              v-show="
                (!filterForCategory &&
                  file.media_category_id === null &&
                  checkExtension(file.extension, file.isFile)) ||
                (filterForCategory &&
                  file.media_category_id === filterForCategory &&
                  checkExtension(file.extension, file.isFile))
              "
              :key="file.id"
              class="files-grid__single-file details-cards"
            >
              <div @click="openPreview(file)">
                <div class="top-course">
                  <img
                    class="top-course__thumbnail"
                    :src="getFileThumbnail(file)"
                    :alt="file.urlname"
                  />
                </div>
              </div>

              <div class="settings-course row pt-3 pb-0">
                <div class="content-course col-7 py-0">
                  <h3 class="fw-bold m-0 p-0" style="text-transform: uppercase">
                    {{ file.filename }}
                  </h3>
                </div>
                <div class="settings col-5">
                  <span class="float-right">
                    <md-menu
                      md-size="auto"
                      :md-offset-x="-180"
                      :md-offset-y="-10"
                    >
                      <md-button md-menu-trigger>•••</md-button>

                      <md-menu-content>
                        <md-menu-item
                          v-if="getProfile.roles.includes('admin')"
                          @click="openAssignCategory(file)"
                        >
                          Agregar a categoria
                        </md-menu-item>
                        <md-menu-item
                          v-if="getProfile.roles.includes('admin')"
                          @click="openMediaEdit(file)"
                        >
                          Editar
                        </md-menu-item>
                        <md-menu-item
                          v-if="file.isFile"
                          :href="file.url"
                          @click.prevent="downloadItem(file)"
                        >
                          Descargar
                        </md-menu-item>
                        <md-menu-item
                          v-else
                          :href="file.url"
                          @click.prevent="openPreview(file)"
                          >Ver</md-menu-item
                        >
                        <md-menu-item
                          v-if="getProfile.roles.includes('admin')"
                          @click.prevent="deleteFile(file)"
                        >
                          Eliminar
                        </md-menu-item>
                        <md-menu-item
                          v-if="getProfile.roles.includes('admin')"
                          @click.prevent="openShareToPartner(file)"
                        >
                          Compartir con socio
                        </md-menu-item>
                      </md-menu-content>
                    </md-menu>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    END SECTION FILES AND CATEGORIES-->

    <!--    ###### SECTIONS MODALS ######-->
    <!--add or edit category-->
    <Category
      :item="fileSelected.category"
      :active="modals.category"
      @close-modal="closeModal($event)"
    />
    <!--assign category to file-->
    <CategoryToFile
      :item="fileSelected.assignCategory"
      :active="modals.assignCategory"
      @close-modal="closeModal($event)"
    />
    <!--share file with partner-->
    <ShareFilePartner
      :item="fileSelected.addPartner"
      :active="modals.addPartner"
      @close-modal="closeModal($event)"
    />
    <!--share category with partner-->
    <ShareCategoryPartner
      :item="fileSelected.shareCategoryFile"
      :active="modals.shareCategoryFile"
      @close-modal="closeModal($event)"
    />
    <!--Preview file-->
    <ViewFile
      :item="fileSelected.selectedFile"
      :active="modals.selectedFile"
      @close-modal="closeModal($event)"
    />
    <!--media image - video - file-->
    <MediaFiles
      :item="fileSelected.mediaFiles"
      :type-file="typeFile"
      :category-selected="categorySelected"
      :active="modals.mediaFiles"
      @close-modal="closeModal($event)"
    />
    <!--media only url-->
    <MediaUrl
      :item="fileSelected.mediaUrl"
      :active="modals.mediaUrl"
      :category-selected="categorySelected"
      @close-modal="closeModal($event)"
    />
    <!--    ###### END SECTIONS MODALS ######-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Axios from 'axios';

import {
  MEDIA_GET_CATEGORIES,
  MEDIA_GET_FILES,
  MEDIA_DELETE_FILE,
  MEDIA_DELETE_FILE_VIDEO,
  MEDIA_DELETE_CATEGORY,
} from '@/store/actions/media';

import { USER_GET_PARTNERS } from '@/store/actions/user';
import {
  CategoryToFile,
  MediaFiles,
  MediaUrl,
  ShareCategoryPartner,
  ShareFilePartner,
  ViewFile,
  Category,
} from '@/components/Actives/Modals';

export default {
  name: 'MediaActive',
  components: {
    Category,
    CategoryToFile,
    ShareFilePartner,
    ShareCategoryPartner,
    ViewFile,
    MediaFiles,
    MediaUrl,
  },
  data: function () {
    return {
      fileSelected: {
        category: {},
        assignCategory: {},
        addPartner: {},
        shareCategoryFile: {},
        selectedFile: {},
        mediaFiles: {},
        mediaUrl: {},
      },
      modals: {
        selectedFile: false,
        category: false,
        mediaFiles: false,
        assignCategory: false,
        addPartner: false,
        shareCategoryFile: false,
        mediaUrl: false,
      },
      typeFile: null,

      value: null,
      categorySelected: null,
      isSelected: false,
      categoriesToShow: [],
      counterFilesForCategory: [],
      valueSelectPartners: [],
      selectPartners: [],
      partners: [],
      closeOnClick: true,
      closeOnSelect: true,
      type: '',
      archivos: [],
      options: ['Archivos', 'Imagen', 'Video', 'Enlace'],
      newList: [],
      selectedFile: null,
      filterForCategory: null,
    };
  },
  computed: {
    ...mapGetters(['getMediaCategories', 'getMediaFiles', 'getProfile']),
  },
  watch: {
    getMediaFiles: function (newValue) {
      this.archivos = newValue;
    },
    getMediaCategories: function (newValue) {
      if (this.categorySelected === null) {
        this.isSelected = false;
        this.categoriesToShow = newValue;
      } else {
        this.isSelected = true;
        this.categoriesToShow = [];
        const categories = newValue.filter(
          (categorie) => categorie?.id === this.categorySelected,
        );
        categories.forEach((categorie) =>
          this.categoriesToShow.push(categorie),
        );
      }
    },
    type: function () {
      if (!this.type || this.type === '') this.archivos = this.getMediaFiles;
      else {
        this.archivos = this.getMediaFiles.filter((arch) =>
          arch.filename.toLowerCase().includes(this.type.toLowerCase()),
        );
      }
    },
  },
  mounted() {
    this.$store
      .dispatch(MEDIA_GET_CATEGORIES)
      .then((resp) => (this.categoriesToShow = resp.data));
    this.$store
      .dispatch(MEDIA_GET_FILES)
      .then(({ data }) => (this.archivos = data));
    this.$store.dispatch(USER_GET_PARTNERS);
  },
  methods: {
    openCategory(value = {}) {
      this.fileSelected.category = value;
      this.modals.category = true;
    },
    openAssignCategory(file) {
      this.modals.assignCategory = true;
      this.fileSelected.assignCategory = file;
    },
    openShareToPartner(file) {
      this.modals.addPartner = true;
      this.fileSelected.addPartner = file;
    },
    openShareCategoryToPartner(item) {
      this.modals.shareCategoryFile = true;
      this.fileSelected.shareCategoryFile = item;
    },
    openPreview(file) {
      console.log(file);
      if (file.isFile) {
        this.fileSelected.selectedFile = file;
        this.modals.selectedFile = true;
      } else if (file.type == 'video') {
        this.fileSelected.selectedFile = file;
        this.modals.selectedFile = true;
      } else {
        window.open(file.url);
      }
    },
    openMediaEdit(file) {
      console.log('Edit :v 2');
      if (file.type === 'url') this.openMediaUrl(file);
      else {
        this.modals.mediaFiles = true;
        this.fileSelected.mediaFiles = file;
        this.typeFile = file.type;
        //console.log(this.$store);
      }
      //console.log(file);
    },
    openMedia(type) {
      this.modals.mediaFiles = true;
      this.fileSelected.mediaFiles = {};
      this.typeFile = type;
    },
    openMediaUrl(file = {}) {
      this.modals.mediaUrl = true;
      this.fileSelected.mediaUrl = file;
    },
    closeModal(type) {
      this.fileSelected[type] = {};
      this.modals[type] = false;
    },
    notifyAlert(message = '', type = 'success') {
      this.$notify({
        type,
        verticalAlign: 'bottom',
        horizontalAlign: 'left',
        message,
      });
    },
    handleDelete(data, category) {
      this.counterFilesForCategory = this.archivos.filter(
        (files) => files.media_category_id === category.id,
      );
      if (this.counterFilesForCategory.length === 0) {
        category.deleteFile = null;
        this.$store
          .dispatch(MEDIA_DELETE_CATEGORY, category)
          .then(() => {
            this.notifyAlert('Se ha eliminado la categoria.');
            this.filterForCategory = null;
            this.isSelected = false;
            this.categorySelected = null;
          })
          .catch(() =>
            this.notifyAlert('No se ha podido eliminar la categoria', 'danger'),
          );
      }
      if (this.counterFilesForCategory.length > 0)
        this.notifyAlert(
          'Para eliminar una categoria, debes remover primero todos sus archivos.',
          'danger',
        );
    },
    handleChangeFilter(e) {
      const newList = [];
      e.map((extensions) =>
        extensions.split(', ').map((ex) => newList.push(ex)),
      );
      this.newList = newList;
    },
    checkExtension(extension, isFile) {
      if (this.newList.length === 0) return true;

      if (this.newList.length > 0) {
        if (this.newList.some((list) => list === 'url') && !isFile) return true;
        if (this.newList.some((list) => list !== 'url'))
          return this.newList.some((list) => list === extension);
      }
    },
    getFileThumbnail(file) {
      const typeThumbnail = {
        video: file.thumbnail
          ? file.thumbnail
          : require(`@/assets/images/media/video-background.png`),
        image: file.url,
        file: require(`@/assets/images/media/file-background.png`),
        url: require(`@/assets/images/media/url-background.png`),
      };
      return typeThumbnail[file.type];
    },
    deleteFile(file) {
      console.log(file);
      if (file.type == 'video') {
        this.$store.dispatch(MEDIA_DELETE_FILE_VIDEO, file);
      } else {
        this.$store.dispatch(MEDIA_DELETE_FILE, file);
      }
    },
    downloadItem(file) {
      Axios.get(file.url, { responseType: 'blob' }).then((response) => {
        const blob = new Blob([response.data]);
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = file.filename;
        link.setAttribute('download', `${file.filename}.${file.extension}`);
        link.click();
        URL.revokeObjectURL(link.href);
      });
    },
    changeCategoryFiles(category) {
      this.categorySelected = category?.id || null;

      if (this.categorySelected === null) {
        this.isSelected = false;
        this.categoriesToShow = this.getMediaCategories;
      }

      if (this.categorySelected !== null) {
        this.isSelected = true;
        this.categoriesToShow = [];
        const categories = this.getMediaCategories.filter(
          (categorie) => categorie?.id === this.categorySelected,
        );
        categories.forEach((categorie) =>
          this.categoriesToShow.push(categorie),
        );
      }

      this.filterForCategory = category?.id || null;
    },
  },
};
</script>

<style scoped>
.md-field {
  min-height: inherit;
  padding: 0.4rem 0.1rem;
}
.md-field:after,
.md-field:before {
  display: none;
}

.container-video-media-form .filter {
  margin-left: 0px;
}

.container-video-media-form input {
  padding-left: 45px;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  width: 370px;
  border-radius: 3px;
}
.form-control {
  height: 50px;
  margin-bottom: 10px;
}

.container-video-media-form .btn-course {
  padding: 11px 20px;
  font-size: 13px;
  margin-top: 1px;
  height: 50px;
}
</style>
