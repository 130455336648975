<template>
  <div>
    <md-dialog :md-active.sync="open" class="modal-agg-category">
      <span class="material-icons close text-right" @click="closeModal"
        >close</span
      >
      <div class="body-modal-agg-category">
        <md-dialog-title>COMPARTIR CON SOCIO</md-dialog-title>

        <div class="form-group mt-3">
          <multiselect
            v-model="val"
            tag-placeholder="Add this as new tag"
            placeholder="Busca por correo"
            label="name"
            track-by="code"
            :options="partners"
            :multiple="true"
            :taggable="true"
          />
        </div>

        <md-dialog-actions>
          <md-button
            class="md-button md-primary md-theme-default"
            @click="closeModal"
            >CANCELAR</md-button
          >
          <md-button class="md-button md-primary md-theme-default" @click="send"
            >GUARDAR</md-button
          >
        </md-dialog-actions>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';
import {
  MEDIA_GET_PARTNERS_BY_FILE,
  MEDIA_SHARE_FILE_TO_PARTNERS,
} from '@/store/actions/media';

export default {
  components: {
    Multiselect,
  },
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      val: null,
      partners: [],
    };
  },
  methods: {
    notifyAlert(message = '', type = 'success') {
      this.$notify({
        type,
        verticalAlign: 'bottom',
        horizontalAlign: 'left',
        message,
      });
    },
    closeModal() {
      this.$emit('closeModal', 'addPartner');
      this.val = [];
      this.partners = [];
    },
    send() {
      const idPartners = [];
      this.val.forEach((users) => idPartners.push(users.code));

      const data = { ids: idPartners, file_id: this.item.id };

      this.$store
        .dispatch(MEDIA_SHARE_FILE_TO_PARTNERS, data)
        .then((res) => this.notifyAlert('Se ha compartido el archivo'))
        .catch(() => this.notifyAlert('No se ha podido compartir', 'danger'))
        .finally(() => this.closeModal());
    },
  },
  watch: {
    item: function (newVal) {
      const items = Object.values(this.getPartners);
      items.forEach((partner) => {
        this.partners.push({ name: partner.email, code: partner.id });
      });
      this.$store
        .dispatch(MEDIA_GET_PARTNERS_BY_FILE, newVal.id)
        .then(({ data }) => {
          const usersEmail = [];
          data.forEach((data) =>
            usersEmail.push({ name: data.email, code: data.id }),
          );
          this.val = usersEmail;
        });
    },
  },
  computed: {
    open: {
      // getter
      get: function () {
        return this.active;
      },
      // setter
      set: function () {
        this.closeModal();
      },
    },
    ...mapGetters(['getPartners']),
  },
};
</script>
