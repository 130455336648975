const data = {
  PROFIT_METRIC: `
    <div class="text-left p-3 tooltip-dashboard__info">
      <h4>Ingresos</h4>
      <p class="">El total de ingresos generado por la plataforma en la fecha seleccionada.      </p>
    </div>
  `,
  USER_METRIC: `
    <div class="text-left p-3 tooltip-dashboard__info">
      <h4>Nuevos usuarios</h4>
      <p class="">El total de nuevos usuarios registrados en la plataforma en la fecha seleccionada.</p>
    </div>
  `,
  // `
  //   <div class="p-3 text-left tooltip-dashboard__info">
  //     <h4>Nuevos socios</h4>
  //     <p class="">El total de nuevos socios registrados en la plataforma en la fecha seleccionada.      </p>
  //     </div>
  //   </div>
  // `,
};

export default data;
