var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 pl-0",on:{"click":function($event){return _vm.changeSelectedItem(_vm.metricOptions.PROFIT_METRIC)}}},[_c('card',{staticClass:"general-stats-container__box cursor details-containers-admin px-0",class:_vm.selectedItem === _vm.metricOptions.PROFIT_METRIC
            ? 'border-color-bottom-select'
            : 'border-color-bottom',attrs:{"header-classes":"bg-transparent border-left px-0"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"row general-stats-container__box__header"},[_c('h2',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
                content: _vm.tooltipQuestion[_vm.metricOptions.PROFIT_METRIC],
              }),expression:"{\n                content: tooltipQuestion[metricOptions.PROFIT_METRIC],\n              }",modifiers:{"right":true}}],staticClass:"color-text color-dark-hover pl-2"},[_vm._v(" Ingresos "),_c('i',{staticClass:"fas fa-question-circle"})])])]},proxy:true}])},[_c('h2',{staticClass:"border-left px-0"},[_c('span',{staticClass:"pl-2"},[_vm._v(" $ "+_vm._s(Number.parseFloat(_vm.getMetricGeneral.profit.total).toFixed(2))+" ")])]),_c('span',{class:{
            'chart_increase chart_increase--positive pl-2':
              _vm.getMetricGeneral.profit.variation > 0,
            'chart_increase chart_increase-negative pl-2':
              _vm.getMetricGeneral.profit.variation < 0,
            'chart_increase chart_increase--neutro pl-2':
              _vm.getMetricGeneral.profit.variation === 0,
          }},[(_vm.getMetricGeneral.profit.variation)?_c('span',{staticClass:"background-icon"},[(_vm.getMetricGeneral.profit.variation > 0)?_c('i',{staticClass:"fa fa-arrow-up ml-0 color-arrow-green pr-2",attrs:{"aria-hidden":"true"}}):(_vm.getMetricGeneral.profit.variation < 0)?_c('i',{staticClass:"fa fa-arrow-down color-arrow-red pr-2",attrs:{"aria-hidden":"true"}}):_vm._e()]):_vm._e(),(_vm.getMetricGeneral.profit.variation > 0)?_c('span',{staticClass:"color-text color-arrow-green"},[_vm._v(_vm._s(_vm.getMetricGeneral.profit.variation.toFixed(2))+"%")]):(_vm.getMetricGeneral.profit.variation < 0)?_c('span',{staticClass:"color-text color-arrow-red"},[_vm._v(_vm._s(_vm.getMetricGeneral.profit.variation.toFixed(2))+"%")]):_c('span',{staticClass:"color-text"},[_vm._v(_vm._s(_vm.getMetricGeneral.profit.variation.toFixed(2))+"%")])])])],1),_c('div',{staticClass:"col-md-4 pl-0",on:{"click":function($event){return _vm.changeSelectedItem(_vm.metricOptions.USER_METRIC)}}},[_c('card',{staticClass:"general-stats-container__box cursor details-containers-admin",class:_vm.selectedItem === _vm.metricOptions.USER_METRIC
            ? 'border-color-bottom-select'
            : 'border-color-bottom',attrs:{"header-classes":"bg-transparent border-left px-0"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"row general-stats-container__box__header"},[_c('h2',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:({
                content: _vm.tooltipQuestion[_vm.metricOptions.USER_METRIC],
              }),expression:"{\n                content: tooltipQuestion[metricOptions.USER_METRIC],\n              }",modifiers:{"right":true}}],staticClass:"pl-2 color-text color-dark-hover detail-labels"},[_vm._v(" Nuevos usuarios "),_c('i',{staticClass:"fas fa-question-circle"})])])]},proxy:true}])},[_c('h2',{staticClass:"border-left px-0"},[_c('span',{staticClass:"pl-2"},[_vm._v(" "+_vm._s(Number.parseFloat(_vm.getMetricGeneral.user.total))+" ")])]),_c('span',{class:{
            'chart_increase chart_increase--positive pl-2':
              _vm.getMetricGeneral.user.variation > 0,
            'chart_increase chart_increase-negative pl-2':
              _vm.getMetricGeneral.user.variation < 0,
            'chart_increase chart_increase--neutro pl-2':
              _vm.getMetricGeneral.user.variation === 0,
          }},[(_vm.getMetricGeneral.user.variation)?_c('span',{staticClass:"background-icon"},[(_vm.getMetricGeneral.user.variation > 0)?_c('i',{staticClass:"fa fa-arrow-up ml-0 color-arrow-green pr-2",attrs:{"aria-hidden":"true"}}):(_vm.getMetricGeneral.user.variation < 0)?_c('i',{staticClass:"fa fa-arrow-down color-arrow-red pr-2",attrs:{"aria-hidden":"true"}}):_vm._e()]):_vm._e(),(_vm.getMetricGeneral.user.variation > 0)?_c('span',{staticClass:"color-text color-arrow-green"},[_vm._v(_vm._s(_vm.getMetricGeneral.user.variation.toFixed(2))+"%")]):(_vm.getMetricGeneral.user.variation < 0)?_c('span',{staticClass:"color-text color-arrow-red"},[_vm._v(_vm._s(_vm.getMetricGeneral.user.variation.toFixed(2))+"%")]):_c('span',{staticClass:"color-text"},[_vm._v(_vm._s(_vm.getMetricGeneral.user.variation.toFixed(2))+"%")])])])],1),_c('div',{staticClass:"col-md-4"})])
}
var staticRenderFns = []

export { render, staticRenderFns }